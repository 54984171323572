<div *ngIf="cart" class="calculator-form">
  <div class="calculator-form-title">{{ 'cart.header' | translate }}
    <span *ngIf="hasBasketHeaderCaption">{{ 'cart.headerCaption' | translate }}</span>
  </div>
  <div class="cart-items-table table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">{{ 'cart.column-2-caption' | translate }}</th>
          <th scope="col">{{ 'cart.column-3-caption' | translate }} CO<sub>2</sub>e</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of cart.items; let i = index;">
          <td *ngIf="!item.vat">
            <div  (click)="removeItem(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
            </div>
          </td>
          <td *ngIf="item.emissionsItem">
            <div *ngFor="let description of item.emissionsItem.description" class="description">{{ description }}</div>
          </td>
          <td *ngIf="item.voucherCode">
            <div class="description">{{ 'cart.discount-caption' | translate }} {{ item.voucherCode.code }}</div>
            <div class="description">{{ item.voucherCode.description }}</div>
          </td>
          <td *ngIf="!item.vat">
            {{ item.emissions | number:'1.2-2' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="cart-summary-table table-responsive">
    <table class="table">
      <tbody>
        <tr>
          <td></td>
          <td>{{ 'cart.total-caption' | translate }} CO<sub>2</sub>e {{ 'cart.tonnes-caption' | translate }}</td>
          <td>{{ cart.totalTonnes | number:'1.2-2' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <form [formGroup]="vatForm">
  <div class="cart-country-table table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">
            <div>{{'cart.country-caption' | translate }}</div>
          </th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">
            <div>{{'cart.address-caption' | translate }}</div>
          </th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{'cart.vat-caption' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <select 
            name="vatCountry"
            class="form-control"
            [formControl]="vatForm.controls['vatCountry']"
            (change)="changeCountry($event.currentTarget.value)"
            >
              <option
                *ngFor="let country of countries"
                value="{{ country }}"
              >
                {{ country }}
              </option>
            </select>
          </td>
          <td>
            <input 
            type="text"
            name="vatAddress"
            class="form-control"
            placeholder="123 Main St. City, Postal"
            [formControl]="vatForm.controls['vatAddress']"
            (input)="changeAddress($event.currentTarget.value)">
          </td>
          <td>{{ cart.currency.symbol | currencySymbol }}{{ cart.vatAmount | number:'1.2-2' }}</td>
        </tr>
        <tr *ngIf="required">
          <td>&nbsp;</td>
          <td class="text-right text-danger">
              {{ 'cart.vat-prompt' | translate }}
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
 </form>
  <div class="cart-pricing-table table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">
            <div>{{ 'cart.portfolio-caption' | translate }}</div>
          </th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.uc-tonnes-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.price-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.vat-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.total-cost-caption' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'cart.message-caption' | translate }}</td>
          <td>{{ cart.totalTonnes | number:'1.2-2' }}</td>
          <td>{{ cart.currency.symbol | currencySymbol }}{{ cart.costPerTonne | number:'1.2-2' }}</td>
          <td>{{ cart.currency.symbol | currencySymbol }}{{ cart.vatAmount | number:'1.2-2' }}</td>
          <td><span [ngClass]="{ 'discount': cart.hasDiscount }">{{ cart.currency.symbol | currencySymbol }}{{ cart.totalCost | number:'1.2-2' }}</span></td>
        </tr>
        <tr *ngIf="!hasMinimum">
          <td colspan="5" class="text-right text-danger">
              {{ 'cart.minimum-amount' | translate }} {{ cart.currency.symbol | currencySymbol }}{{ expectedMinimum | number:'1.2-2' }}
          </td>
        </tr>
        <tr *ngIf="hasShareControl">
          <td class="share">
            <input type="checkbox" id="share" name="share" value="share" (click)="toggleShare()">
            <label for="share"> It’s ok to share my offset purchase with others (learn more)</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
</div>
<div>
  <app-post-purchase-navigation
    [target]="data.id"
    [hasVoucherForm]="true"
    [activated]="paying"
    [nextEnabled]="hasMinimum && proceed"
    (next)="next()"
    (back)="back()"
    (voucherCode)="voucherCode($event)">
  </app-post-purchase-navigation>
</div>
