import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-climatecare-freight',
  templateUrl: './freight.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './freight.component.css' ]
})
export class ClimateCareFreightComponent implements OnInit, FeatureComponent {

  data: any;

  freightForm: FormGroup;

  freightClassTypes: Array<EmissionsOption>;

  freightDistanceUnits: Array<EmissionsOption>;

  weightUnits: Array<EmissionsOption>;

  freightClassHasTime = true;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private cartService: CartService;

  private emissionsService: EmissionsService;

  private formBuilder: FormBuilder;

  private freightMediumType: string;

  constructor(private injector: Injector) {
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.freightClassTypes = this.emissionsService.getFieldOptions('freightClassTypes');
    this.freightMediumType = this.freightClassTypes[0].correletadField;
    this.freightDistanceUnits = this.freightClassTypes[0].units[0];
    this.weightUnits = this.freightClassTypes[0].units[1];

    this.freightForm = this.formBuilder.group({
      distance: [ 0, [ Validators.required, Validators.min(1) ] ],
      unit: [ this.freightDistanceUnits[0].apiKey, Validators.required ],
      medium: [ this.freightMediumType, Validators.required ],
      class: [ this.freightClassTypes[0].apiKey, Validators.required ],
      weight: [ 0, [ Validators.required, Validators.min(1) ] ],
      weightUnit: [ this.weightUnits[0].apiKey, Validators.required ]
    });

    this.freightForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.freightForm.valid) {
        this.getEmissions(form);
      }
    });
  }

  ngOnInit(): void {}

  changeClassType(index: number) {
    this.freightMediumType = this.freightClassTypes[index].correletadField;
    this.freightClassHasTime = this.freightMediumType === 'air' ? true : false;

    this.freightDistanceUnits = this.freightClassTypes[index].units[0];
    this.weightUnits = this.freightClassTypes[index].units[1];

    this.freightForm.patchValue({
      medium: this.freightMediumType
    });
  }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const distance = form['distance'];
    const unit = form['unit'];
    const medium = form['medium'];
    const freightClass = form['class'];
    const weight = form['weight'];
    const weightUnit = form['weightUnit'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          distance,
          unit,
          medium,
          freightClass,
          weight,
          weightUnit
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_FREIGHT
      );
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getFreightEmissions(
      form['distance'],
      form['unit'],
      form['medium'],
      form['class'],
      form['weight'],
      form['weightUnit']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.freightForm.patchValue({
      distance: 0,
      unit: this.freightDistanceUnits[0].apiKey,
      medium: this.freightMediumType,
      class: this.freightClassTypes[0].apiKey,
      weight: 0,
      weightUnit: this.weightUnits[0].apiKey
    });
  }
}
