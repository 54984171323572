<div class="calculator-form row">
  <div class="col-md-7">
    <form id="accommodationsForm" [formGroup]="accommodationsForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">ACCOMMODATIONS</div>
        <p>Calculate and offset accomodation:</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Country</label>
        <select class="form-control" formControlName="source">
          <option
            *ngFor="let country of countries"
            value="{{ country }}"
          >
            {{ country }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Type</label>
        <select class="form-control" formControlName="type" (change)="changeAccommodationType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let accommodationType of accommodationTypes"
            value="{{ accommodationType.apiKey }}"
          >
            {{ accommodationType.label }}
          </option>
        </select>
      </div>
      <div *ngIf="accommodationLevels" class="form-group inline">
        <label class="col-form-label">Level</label>
        <select class="form-control" formControlName="accommodationType">
          <option
            *ngFor="let accommodationLevel of accommodationLevels"
            value="{{ accommodationLevel.apiKey }}"
          >
            {{ accommodationLevel.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline passengers">
        <label for="passengers" class="col-form-label">Nights</label>
        <input  
          type="number"
          min="1"
          class="form-control"
          formControlName="nights">
      </div>
      <div class="form-group inline passengers">
        <label for="passengers" class="col-form-label">Guests</label>
        <input  
          type="number"
          min="1"
          class="form-control"
          formControlName="guests">
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="accommodationsForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>
