<div class="purchase-outer">
  <div class="purchase-inner">
    <div class="calculator-purchase-emissions-title">{{ 'purchase-emissions.header' | translate }}</div>
    <hr/>
    <div class="total-emissions">
      <div>{{ totalEmissions | number:'1.2-2' }}</div>
      <div>{{ 'purchase-emissions.tonnes-of' | translate }} CO<sub>2</sub>e</div>
    </div>
  </div>
  <div class="purchase-inner-button">
    <button [ngStyle]="{ 'background': postPurchaseButtonColor, 'border-color': postPurchaseButtonColor }" [disabled]="disableButton" (click)="addToBasket()" class="btn">
      <div *ngIf="emissionsRequestActivated || !addedCaption" class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="!emissionsRequestActivated">{{ addedCaption }}</div>
    </button>
  </div>
  <div *ngIf="showFeatureNavigation" class="feature-nav">
    <app-feature-scroller
      [enableNext]="enableNext"
      (forward)="nextFeature()"
      (back)="previousFeature()"
    ></app-feature-scroller>
  </div>
</div>
