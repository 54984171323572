import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Project, ProjectDetails, ContentBlock } from '../calculator-api/project.interface';

const PROP_DESCRIPTION = 'description';
const PROP_BASE_CURRENCY = 'baseCurrency';

const dataPropertiesMap = {
  id: 'id',
  name: 'title',
  title: 'title',
  base64_image_data: 'splash',
  cost_currency_code_iso_4217: 'baseCurrency',
  cost_per_tonne_co2e: 'costPerTonne',
  contents: 'contents',
  paragraph: 'paragraph',
  link: 'link',
  description: 'description'
};

@Injectable()
export class ProjectService {

  private projects: BehaviorSubject<Array<Project>>;

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration) {

    this.projects = new BehaviorSubject<Array<Project>>([]);

  }

  getStoredProjects(): Observable<Array<Project>> {
    return this.projects;
  }

  storeProjects(): void {
    this.getProjects().subscribe((projects: Array<Project>) => {
      this.projects.next(projects);
    });
  }

  getProjects(): Observable<Array<Project> | Array<string>> {
    return this.httpClient.get<Array<Project>>(
      this.appConfiguration.clientConfiguration.endpoints['getProjects'],
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((data: Array<any>) => {
        return this.mapProjects(data);
      }),
      map((products: Array<Project>) => {
        return products;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }

  private mapProjects(data: Array<any>): Array<Project> {
    const projects: Array<Project> = [];

    data.forEach((projectData: any) => {
      const project: Project = {};

      for (const prop in projectData) {
        if (projectData.hasOwnProperty(prop)) {
          // code here
          const mapped = dataPropertiesMap[prop];

          if (mapped &&
            mapped !== PROP_DESCRIPTION &&
            mapped !== PROP_BASE_CURRENCY) {
            project[mapped] = projectData[prop];
          } else if (mapped && mapped === PROP_DESCRIPTION) {
            project.projectDetails = this.mapProjectDetails(projectData[prop]);
          } else if (mapped && mapped === PROP_BASE_CURRENCY) {
            const currency = this.mapCurrency(projectData[prop]);
            project.baseCurrency = currency;
            project.quotedCurrency = currency;
          }
        }
      }

      project.quotedCostPerTonne = project.costPerTonne;

      projects.push(project);
    });

    return projects;
  }

  private mapProjectDetails(data: any): ProjectDetails {
    const projectDetails: ProjectDetails = {};

    try {
      const details: any = JSON.parse(data);

      if (details && details.title && typeof (details.title) === 'string') {
        projectDetails.title = details.title;
      }

      if (details && details.contents && Array.isArray(details.contents)) {
        if (!projectDetails.contents) {
          projectDetails.contents = [];
        }

        details.contents.forEach((contentBlock: ContentBlock) => {
          projectDetails.contents.push(contentBlock);
        });
      }
    } catch (e) { }

    return projectDetails;
  }

  private mapCurrency(iso2: string): any {
    let currency;

    const payableCurrencies =
      this.appConfiguration.clientConfiguration.payableCurrencies;


    for (const payableCurrency of payableCurrencies) {
      if (payableCurrency.iso2 === iso2) {
        currency = payableCurrency;
        break;
      }
    }

    return currency;
  }
}
