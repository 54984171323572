import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabContentHostDirective } from './tab-content-host.directive';

@NgModule({
  declarations: [
    TabContentHostDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabContentHostDirective
  ],
})
export class TabContentHostModule { }
