import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-calculator-header',
  templateUrl: './calculator-header.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './calculator-header.component.css']
})
export class CalculatorHeaderComponent implements OnInit, FeatureComponent {
  data: any;

  @Input()
  showCheckout = false;

  @Input()
  showCurrencySelector = false;

  @Input()
  totalTonnes = 0;

  @Input()
  currencies = [];

  @Input()
  logoImg;

  @Input()
  showDefaultHeader = true;

  @Output()
  checkout: EventEmitter<any>;

  selectedCurrency: string;

  constructor(private injector: Injector) {
    this.checkout = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.selectedCurrency = this.currencies[0].iso2;
  }

  selectCurrency(selectCurrencyIndex: number): void {
    this.selectedCurrency = this.currencies[selectCurrencyIndex].iso2;

    this.injector.get(AppConfiguration).clientConfiguration.
      updateSelectedCurrency(selectCurrencyIndex);
  }

  clickCheckout(): void {
    this.checkout.emit('');
  }
}
