import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppConfiguration } from './app.configuration';
import { AppRoutingModule } from './app-routing.module';
import { CalculatorApi } from '../../shared/services/calculator-api/calculator-api.service';
import { ClimateCareModule } from '../../clients/climatecare/climatecare.module';
import { PostMessageService } from '../../shared/services/post-message/post-message.service';
import { CalculatorDataResolverService } from '../../shared/services/calculator-api/calculator-data-resolver.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/climatecare/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClimateCareModule,
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppConfiguration,
    CalculatorApi,
    PostMessageService,
    CalculatorDataResolverService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
