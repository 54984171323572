import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { EmissionsItem } from './emissions-item.interface';

export class EmissionsItemCatalog {
  static OFFSET_CATEGORY_GENERAL: string = '';
  static OFFSET_CATEGORY_CARGO: string = '';
  static OFFSET_CATEGORY_TRANSPORTAION: string = '';
  static OFFSET_CATEGORY_TRANSPORTAION_GENERIC: string = '';
  static OFFSET_CATEGORY_ENERGY: string = '';
  static OFFSET_CATEGORY_CONSUMPTION: string = '';
  static OFFSET_CATEGORY_ACCOMMODATION: string = '';
  static OFFSET_CATEGORY_FREIGHT: string = '';
  static OFFSET_CATEGORY_WASTE: string = '';
  static OFFSET_CATEGORY_EVENTS: string = '';
  static OFFSET_CATEGORY_MAIL: string = '';
  static OFFSET_CATEGORY_DROPS: string = '';

  static OFFSET_TYPE_GENERAL_MESSAGE: string = '';
  static OFFSET_TYPE_FLIGHT_MESSAGE: string = '';
  static OFFSET_TYPE_TRANSPORTAION_MESSAGE: string = '';
  static OFFSET_TYPE_ENERGY_MESSAGE: string = '';
  static OFFSET_TYPE_CONSUMPTION_MESSAGE: string = '';
  static OFFSET_TYPE_ACCOMMODATION_MESSAGE: string = '';
  static OFFSET_TYPE_FREIGHT_MESSAGE: string = '';
  static OFFSET_TYPE_WASTE_MESSAGE: string = '';
  static OFFSET_CATEGORY_EVENTS_MESSAGE: string = '';
  static OFFSET_RENEWABLE_MESSAGE: string = '';
  static OFFSET_CATEGORY_MAIL_MESSAGE: string = '';
  static OFFSET_CATEGORY_DROP_MESSAGE: string = '';
  static OFFSET_TYPE_CARGO_MESSAGE: string = '';

  private catalog: Map<string, EmissionsItem>;

  constructor(private translate: TranslateService) {
      this.initTranslations();
  }

  getEmissionsItem(catalogName: string): EmissionsItem {
    if (this.catalog.has(catalogName)) {
      return cloneDeep(this.catalog.get(catalogName));
    }
    return;
  }

  private initTranslations() {
    this.translate.get('cart-categories').subscribe((res: string) => {
      EmissionsItemCatalog.OFFSET_CATEGORY_GENERAL = res['offset-category-general'];
      EmissionsItemCatalog.OFFSET_CATEGORY_CARGO = res['offset-category-cargo'];
      EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION = res['offset-category-transportation'];
      EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION_GENERIC = res['offset-category-transportaion-generic'];
      EmissionsItemCatalog.OFFSET_CATEGORY_ENERGY = res['offset-category-energy'];
      EmissionsItemCatalog.OFFSET_CATEGORY_CONSUMPTION = res['offset-category-consumption'];
      EmissionsItemCatalog.OFFSET_CATEGORY_ACCOMMODATION = res['offset-category-accommodation'];
      EmissionsItemCatalog.OFFSET_CATEGORY_FREIGHT = res['offset-category-freight'];
      EmissionsItemCatalog.OFFSET_CATEGORY_WASTE = res['offset-category-waste'];
      EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS = res['offset-category-events'];
      EmissionsItemCatalog.OFFSET_CATEGORY_MAIL = res['offset-category-mail'];
      EmissionsItemCatalog.OFFSET_CATEGORY_DROPS = res['offset-category-drops'];
      EmissionsItemCatalog.OFFSET_TYPE_GENERAL_MESSAGE = res['offset-type-general-message'];
      EmissionsItemCatalog.OFFSET_TYPE_FLIGHT_MESSAGE = res['offset-type-flight-message'];
      EmissionsItemCatalog.OFFSET_TYPE_TRANSPORTAION_MESSAGE = res['offset-type-transportaion-message'];
      EmissionsItemCatalog.OFFSET_TYPE_ENERGY_MESSAGE = res['offset-type-energy-message'];
      EmissionsItemCatalog.OFFSET_TYPE_CONSUMPTION_MESSAGE = res['offset-type-consumption-message'];
      EmissionsItemCatalog.OFFSET_TYPE_ACCOMMODATION_MESSAGE = res['offset-type-accommodation-message'];
      EmissionsItemCatalog.OFFSET_TYPE_FREIGHT_MESSAGE = res['offset-type-freight-message'];
      EmissionsItemCatalog.OFFSET_TYPE_WASTE_MESSAGE = res['offset-type-waste-message'];
      EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS_MESSAGE = res['offset-category-events-message'];
      EmissionsItemCatalog.OFFSET_RENEWABLE_MESSAGE = res['offset-renewable-message'];
      EmissionsItemCatalog.OFFSET_CATEGORY_MAIL_MESSAGE = res['offset-category-mail-message'];
      EmissionsItemCatalog.OFFSET_CATEGORY_DROP_MESSAGE = res['offset-category-drop-message'];
      EmissionsItemCatalog.OFFSET_TYPE_CARGO_MESSAGE = res['offset-type-general-message'];

      this.initCatalog();
    });
  }

  private initCatalog() {
    this.catalog = new Map<string, EmissionsItem>();

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_GENERAL,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_GENERAL,
          EmissionsItemCatalog.OFFSET_TYPE_GENERAL_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_CARGO,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_CARGO,
          EmissionsItemCatalog.OFFSET_TYPE_CARGO_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION,
          EmissionsItemCatalog.OFFSET_TYPE_FLIGHT_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION_GENERIC,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION_GENERIC,
          EmissionsItemCatalog.OFFSET_TYPE_TRANSPORTAION_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_ENERGY,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_ENERGY,
          EmissionsItemCatalog.OFFSET_TYPE_ENERGY_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_CONSUMPTION,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_CONSUMPTION,
          EmissionsItemCatalog.OFFSET_TYPE_CONSUMPTION_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_ACCOMMODATION,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_ACCOMMODATION,
          EmissionsItemCatalog.OFFSET_TYPE_ACCOMMODATION_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_FREIGHT,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_FREIGHT,
          EmissionsItemCatalog.OFFSET_TYPE_FREIGHT_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_WASTE,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_WASTE,
          EmissionsItemCatalog.OFFSET_TYPE_WASTE_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS,
          EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_MAIL,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_MAIL,
          EmissionsItemCatalog.OFFSET_CATEGORY_MAIL_MESSAGE
        ]
      }
    );

    this.catalog.set(
      EmissionsItemCatalog.OFFSET_CATEGORY_DROPS,
      {
        description: [
          EmissionsItemCatalog.OFFSET_CATEGORY_DROPS,
          EmissionsItemCatalog.OFFSET_CATEGORY_DROP_MESSAGE
        ]
      }
    );
  }
}



