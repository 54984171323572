<div class="share">
    <div class="share-wrapper">
        <div>
            <span *ngFor="let sharedLink of sharedLinks">
                <share-button 
                    [theme]="'default'"
                    [button]="sharedLink.platform"
                    [size]="2"
                    [url]="siteUrl"
                    [description]="shareMessage"
                    [autoSetMeta]="true">
                </share-button>
            </span>
        </div>
    </div>
</div>
