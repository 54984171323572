import { Injectable, Injector } from '@angular/core';

import { AdditionalOptionsComponent } from '../additional-options/additional-options.component';
import { BusinessMailComponent } from '../business-mail/business-mail.component';
import { CalculatorHeaderComponent } from '../calculator-header/calculator-header.component';
import { CarbonAnalyticsFooterComponent } from '../carbon-analytics-footer/carbon-analytics-footer.component';
import { CartComponent } from '../cart/cart.component';
import { ClimateCareStartComponent } from '../start/start.component';
import { ClimateCareAccommodationsComponent } from '../accommodations/accommodations.component';
import { ClimateCareEventComponent } from '../event/event.component';
import { ClimateCareConsumptionComponent } from '../consumption/consumption.component';
import { ClimateCareEnergyComponent } from '../energy/energy.component';
import { ClimateCareFlightsComponent } from '../flights/flights.component';
import { ClimateCareFreightComponent } from '../freight/freight.component';
import { ClimateCareRefrigerationComponent } from '../refrigeration/refrigeration.component';
import { ClimateCareTransportationComponent } from '../transportation/transportation.component';
import { ClimateCareWasteComponent } from '../waste/waste.component';
// import { ClpHomeEnergyComponent } from '../../../features/clp/clp-home-energy/clp-home-energy.component';
// import { ClpHolidayComponent } from '../../../features/clp/clp-holiday/clp-holiday.component';
// import { ClpLifestyleComponent } from '../../../features/clp/clp-lifestyle/clp-lifestyle.component';
// import { ClpMobilityComponent } from '../../../features/clp/clp-mobility/clp-mobility.component';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { CustomerDetailsComponent } from '../customer-details/customer-details.component';
import { DirectMailComponent }  from '../direct-mail/direct-mail.component';
import { DoorDropsComponent }  from '../door-drops/door-drops.component';
import { FeatureComponent } from './feature-component.interface';
import { IntroComponent } from '../intro/intro.component';
import { IcroaFooterComponent } from '../icroa-footer/icroa-footer.component';
import { OffsetProjectsComponent } from '../offset-projects/offset-projects.component';
import { PartiallyAddressedMailComponent } from '../partially-addressed-mail/partially-addressed-mail.component';
import { PayOffsetsComponent } from '../pay-offsets/pay-offsets.component';

type ComponentClass = { new (injector: Injector): FeatureComponent };

@Injectable()
export class FeatureComponentRegistryService {

  private componentMap: Map<string, ComponentClass>;

  constructor() {
    this.buildComponentMap();
  }

  getComponent(componentName: string): ComponentClass {
    if (!this.componentMap.has(componentName)) {
      return null;
    }
    return this.componentMap.get(componentName);
  }

  private buildComponentMap() {
    this.componentMap = new Map<string, ComponentClass>();
    this.componentMap.set('AdditionalOptionsComponent', AdditionalOptionsComponent);
    this.componentMap.set('ClimateCareStartComponent', ClimateCareStartComponent);
    this.componentMap.set('ClimateCareAccommodationsComponent', ClimateCareAccommodationsComponent);
    this.componentMap.set('ClimateCareConsumptionComponent', ClimateCareConsumptionComponent);
    this.componentMap.set('ClimateCareEnergyComponent', ClimateCareEnergyComponent);
    this.componentMap.set('ClimateCareFlightsComponent', ClimateCareFlightsComponent);
    this.componentMap.set('ClimateCareFreightComponent', ClimateCareFreightComponent);
    this.componentMap.set('ClimateCareRefrigerationComponent', ClimateCareRefrigerationComponent);
    this.componentMap.set('ClimateCareEventComponent', ClimateCareEventComponent);
    this.componentMap.set('ClimateCareTransportationComponent', ClimateCareTransportationComponent);
    this.componentMap.set('ClimateCareWasteComponent', ClimateCareWasteComponent);
    this.componentMap.set('CartComponent', CartComponent);
    this.componentMap.set('CalculatorHeaderComponent', CalculatorHeaderComponent);
    this.componentMap.set('CarbonAnalyticsFooterComponent', CarbonAnalyticsFooterComponent);
    this.componentMap.set('ConfirmationComponent', ConfirmationComponent);
    this.componentMap.set('IcroaFooterComponent', IcroaFooterComponent);
    this.componentMap.set('OffsetProjectsComponent', OffsetProjectsComponent);
    this.componentMap.set('PayOffsetsComponent', PayOffsetsComponent);
    this.componentMap.set('IntroComponent', IntroComponent);
    this.componentMap.set('CustomerDetailsComponent', CustomerDetailsComponent);
    this.componentMap.set('BusinessMailComponent', BusinessMailComponent);
    this.componentMap.set('DirectMailComponent', DirectMailComponent);
    this.componentMap.set('DoorDropsComponent', DoorDropsComponent);
    this.componentMap.set('PartiallyAddressedMailComponent', PartiallyAddressedMailComponent);
    
    // this.componentMap.set('ClpLifestyleComponent', ClpLifestyleComponent);
    // this.componentMap.set('ClpHomeEnergyComponent', ClpHomeEnergyComponent);
    // this.componentMap.set('ClpMobilityComponent', ClpMobilityComponent);
    // this.componentMap.set('ClpHolidayComponent', ClpHolidayComponent);
  }
}
