import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import { Observable } from 'rxjs';

import { Cart } from './cart.interface';
import { CartService } from './cart.service';

@Injectable()
export class CartResolverService implements Resolve<Cart | Array<string>> {

  constructor(private cartService: CartService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Cart | Array<string>> {
    return this.cartService.getCart();
  }
}
