import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClimateCareTransportationComponent} from './transportation.component';
import {OffsetInputModule} from '../../../shared/components/offset-input/offset-input.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PurchaseEmissionsModule} from '../purchase-emissions/purchase-emissions.module';
import {GenericTypeaheadModule} from '../../../shared/components/generic-typeahead/generic-typeahead.module';

@NgModule({
  declarations: [
    ClimateCareTransportationComponent
  ],
  imports: [
    CommonModule,
    OffsetInputModule,
    FormsModule,
    ReactiveFormsModule,
    PurchaseEmissionsModule,
    GenericTypeaheadModule
  ],
  exports: [
    ClimateCareTransportationComponent
  ],
})
export class ClimateCareTransportationModule {
}
