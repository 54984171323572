import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfiguration } from '../../bootstrap/climatecare/app.configuration';

@Injectable()
export class CalculatorsApiAuthInterceptor implements HttpInterceptor {
  constructor(private appConfiguration: AppConfiguration) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const partnerConfig = this.appConfiguration.clientConfiguration.clientSettings.partnerConfig;

    if (partnerConfig) {
      try {
        request = request.clone({
          setHeaders: {
            authorization: partnerConfig.data.license
          }
        });
      } catch(e) {}
    }

    return next.handle(request);
  }
}
