import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService, PROVIDER_PAYPAL, PROVIDER_STRIPE } from '../../../shared/services/payments/payment.service';

import { IPayPalConfig, ICreateOrderRequest, NgxPaypalComponent, PayPalScriptService } from 'ngx-paypal';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { CartService } from '../../../shared/services/cart/cart.service';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { Project } from '../../../shared/services/calculator-api/project.interface';
import { VoucherCode } from '../../../shared/services/calculator-api/voucher-code.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

const EVENT_NEXT = 'next';
const EVENT_PREV = 'prev';

@Component({
  selector: 'app-post-purchase-navigation',
  templateUrl: './post-purchase-navigation.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './post-purchase-navigation.component.css']
})
export class PostPurchaseNavigationComponent implements OnInit {
  @ViewChild('payPalButton') set payPalButton(paypalElem: NgxPaypalComponent|null) {
    if (paypalElem && this.payPalConfig) {
      // TODO: This a patch for the ngx-paypal plugin.  Each time we change the currency,
      // have to reload the config.  The paypal variable attached to the window does not
      // get removed which affects the re-initializetion.  So we remove it ourselves.
      // delete window['paypal'];

      paypalElem.reinitialize(this.payPalConfig);
    }
  };

  @Input()
  target: string;

  @Input()
  hasNext ?= true;

  @Input()
  nextEnabled ?= true;

  @Input()
  hasMiddle ?= false;

  @Input()
  firstCaption ?:string;

  @Input()
  secondCaption ?:string;

  @Input()
  middleCaption ?= '';

  @Input()
  activated ?= false;

  @Input()
  middleActivated ?= false;

  @Input()
  hasVoucherForm ?= false;

  @Output()
  next: EventEmitter<any>;

  @Output()
  middle: EventEmitter<any>;

  @Output()
  back: EventEmitter<any>;

  @Output()
  voucherCode: EventEmitter<VoucherCode>;

  cart: Cart;

  project: Project;

  payPalConfig: IPayPalConfig;

  voucherForm: FormGroup;

  constructor(
    private appConfiguration: AppConfiguration,
    private calculatorApi: CalculatorApi,
    private cartService: CartService,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private payPalScriptService: PayPalScriptService) {

    this.next = new EventEmitter<any>();
    this.middle = new EventEmitter<any>();
    this.back = new EventEmitter<any>();
    this.voucherCode = new EventEmitter<VoucherCode>();

    this.voucherForm = this.formBuilder.group({
      voucherCode: [ '', Validators.required ]
    });

    this.translateService.get('post-purchase-navigation.back-caption').subscribe((res: string) => {
      if (!this.firstCaption) {
        this.firstCaption = res;
      }
    });

    this.translateService.get('post-purchase-navigation.proceed-caption').subscribe((res: string) => {
      if (!this.secondCaption) {
        this.secondCaption = res;
      }
    })

    this.cartService.
      getCart().
      subscribe((cart: Cart) => {
        this.updateConfig(cart);
      });

    this.calculatorApi.
      getSelectedProject().
      subscribe((project: Project) => {
        this.project = project;
      });
  }

  ngOnInit(): void {}

  clickNext() {
    this.next.emit(null);
  }

  clickMiddle() {
    this.middle.emit(null);
  }

  clickBack() {
    this.back.emit(null);
  }

  getVoucherCode() {
    if (this.voucherForm.get('voucherCode').value) {
      this.calculatorApi.
        getVoucherCode(this.voucherForm.get('voucherCode').value).subscribe(
          (result: any) => {
            if (result.status) {
              this.voucherForm.reset();
            } else {
              this.voucherCode.emit(result);
              this.voucherForm.reset();
            }
          }
      );
    }
  }

  private updateConfig(cart: Cart): void {
    this.cart = cart;
    this.payPalConfig = undefined;
      setTimeout(x => {
        this.createPaypalConfig();
      },200);
  }

  private createPaypalConfig() {
    this.payPalConfig = {
      currency: this.cart.currency.iso2,
      clientId: this.appConfiguration.clientConfiguration.clientSettings.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest> {
          intent: 'CAPTURE',
          purchase_units: [{
              amount: {
                  currency_code: this.cart.currency.iso2,
                  value: this.cart.totalCost,
                  breakdown: {
                      item_total: {
                          currency_code: this.cart.currency.iso2,
                          value: this.cart.totalCost
                      }
                  }
              },
              items: [{
                  name: 'Offset Purchase',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                      currency_code: this.cart.currency.iso2,
                      value: this.cart.totalCost,
                  },
              }]
          }]
      },
      advanced: {
          commit: 'true'
      },
      style: {
          label: 'paypal',
          layout: 'horizontal',
          shape: 'rect',
          size: 'small',
          height: 48,
      },
      onApprove: (data, actions) => {},
      onClientAuthorization: (data) => {

        // TODO: We are currently runnning this from 2 places
        // We should refactor this process so payments are called the same
        // way
        const paymentType = 'offset-purchase';

        this.paymentService.completePayment({
          provider: PROVIDER_PAYPAL,
          cart: this.cart,
          projectId: this.project.id,
          projectName: this.project.title,
          type: paymentType
        }).subscribe((result: any) => {
          this.navigationService.slideActiveTabEnd({
            showNavigationMenu: false,
            showCheckout: false,
            showCurrencySelector: false,
            showDefaultHeader: false,
            isStart: false
          });
        });
      },
      onCancel: (data, actions) => {},
      onError: err => {},
      onClick: (data, actions) => {},
    };
  }
}
