<div *ngIf="showDefaultHeader" class="calculator-row row">
  <div class="col-sm-6 d-table">
    <div class="d-table-cell">
      <div class="client-logo"
        [style.background-image]="'url(../../../../assets/img/climatecare/' + logoImg.name + ')'"
        [style.background-size]="'contain'"
        [style.height]="logoImg.height"
        [style.width]="logoImg.width"></div>
    </div>
    <div [hidden]="!showCurrencySelector" class="currency-select align-top">
      <select
        (change)="selectCurrency($event.currentTarget.selectedIndex)"
        class="custom-select"
      >
        <option *ngFor="let currency of currencies" value="currency.iso2" [selected]="currency.iso2 === selectedCurrency">
          {{ currency.symbol }}
        </option>
      </select>
    </div>
  </div>
  <div [hidden]="!showCheckout" class="col-sm-6">
    <div class="select-project-button-wrapper">
      <button (click)="clickCheckout()" class="select-project-button">
        <span class="cart-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-cart-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
        </span>
        <span>{{ 'cart.select-projects' | translate }}</span>
      </button>
    </div>
    <div class="cart-wrapper">
      <span class="cart-label">{{ 'cart.basket-total-caption' | translate }}</span>
      <span class="cart-amount">{{ totalTonnes | number: "1.2-2" }}</span>
      <span class="cart-unit"> tCO<sub>2</sub>e</span>
    </div>
  </div>
</div>

<div *ngIf="!showDefaultHeader" class="calculator-row row">
  <div class="col-sm-12 d-table">
    <div class="d-table-cell">
      <div class="climatecare-logo no-default"></div>
    </div>
  </div>
</div>
