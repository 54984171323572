import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AppConfiguration, CLIENT_CLIMATECARE } from '../../../bootstrap/climatecare/app.configuration';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { CartService } from '../../../shared/services/cart/cart.service';
import { ExchangeRate } from '../../../shared/services/product/exchange-rate.interface';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { PaymentState } from '../../../shared/components/flex-stripe-card/flex-stripe-card.component';
import { Project } from '../../../shared/services/calculator-api/project.interface';
import { PROJECT_CONSTANTS } from '../../../shared/services/calculator-api/project.constants';
import { ProductService } from '../../../shared/services/product/product.service';
import {
  PaymentService,
  PROVIDER_STRIPE
} from '../../../shared/services/payments/payment.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pay',
  templateUrl: './pay-offsets.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './pay-offsets.component.css']
})
export class PayOffsetsComponent implements OnInit, FeatureComponent {
  data: any;

  cart: Cart;

  detailsForm: FormGroup;

  appConfiguration: AppConfiguration;

  translateService: TranslateService;

  showPayButtonOnValidForm = false;

  paymentState: PaymentState;

  paying = false;

  payCaption = '';

  project: Project;

  customCartHeaderColor = '';

  constructor(private injector: Injector) {
    this.initCurrency();

    this.appConfiguration = this.injector.get(AppConfiguration);

    this.translateService = this.injector.get(TranslateService);

    this.payCaption = this.translateService.instant('pay-offsets.submit-caption');

    this.injector.get(CartService).
      getCart().
      subscribe((cart: Cart) => {
        this.cart = cart;
      });

    this.injector.get(CalculatorApi).
      getSelectedProject().
      subscribe((project: Project) => {
        this.project = project;
      });

    this.customCartHeaderColor =
      this.appConfiguration.clientConfiguration.clientSettings.customCartHeaderColor;
  }

  ngOnInit(): void {}

  pay(): void {
    const paymentType = 'offset-purchase';

    this.paying = true;

    const paymentService = this.injector.get(PaymentService);

    paymentService.
      initPayment(
        PROVIDER_STRIPE,
        CLIENT_CLIMATECARE,
        this.cart,
        this.project.id,
        this.project.title,
        this.paymentState.card,
        this.paymentState.stripe).
      subscribe(
        (result: any) => {
          this.paying = false;
          this.paymentState.card.clear();
          this.injector.get(NavigationService).slideActiveTabForward(
            {
              showNavigationMenu: false,
              showCheckout: false,
              showCurrencySelector: true,
              showDefaultHeader: false,
              isStart: false
            }
          );
        }
      );
  }

  back(): void {
    this.injector.get(NavigationService).slideActiveTabBack({
      showNavigationMenu: false,
      showCheckout: false,
      showCurrencySelector: false,
      showDefaultHeader: true,
      isStart: false
    });
  }

  cardCompleted(paymentState: PaymentState) {
    this.paymentState = paymentState;
  }

  private initCurrency() {
    this.injector.get(AppConfiguration).
      clientConfiguration.
      getSelectedCurrency().
      subscribe((currency: any) => {
      try {
        const project: Project =
          JSON.parse(localStorage.getItem(PROJECT_CONSTANTS.ACTIVE_PROJECT));


        this.injector.get(ProductService).getFxRate(
          project.baseCurrency.iso2,
          currency.iso2
        ).subscribe((exchangeRate: ExchangeRate) => {
          this.injector.get(CartService).
            setCostProperties(project.costPerTonne, currency.iso2, exchangeRate.rate);
        });
      } catch (e) {}
    });
  }
}
