import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';

import { Tab } from '../tab.model';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.css']
})
export class TabContentComponent implements OnInit {

  @Input()
  activeFeature;

  @Input()
  name ?= 'content';

  @Input()
  featureComponents: Array<any>;

  @Input()
  contentComponents: Array<any>;

  constructor() {}

  ngOnInit(): void {}
}
