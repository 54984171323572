import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { NavigationEvent } from './navigation-event.interface';
import { NAVIGATION_SERVICE_CONSTANTS } from './navigation.constants';
import { Feature } from '../../../features/calculators/feature-component-registry/feature-component.interface';

@Injectable()
export class NavigationService {

  private navigationEvent: BehaviorSubject<NavigationEvent>;

  private selectedFeature: BehaviorSubject<Feature>;

  private featureCount: BehaviorSubject<number>;

  constructor(private router: Router) {
    this.navigationEvent = new BehaviorSubject<NavigationEvent>(
      { target: '', action: '' }
    );

    this.selectedFeature = new BehaviorSubject<Feature>(null);
    this.featureCount = new BehaviorSubject<number>(0);
  }

  go(url: string) {
    this.router.navigateByUrl(url);
  }

  bootstrap() {
    this.router.initialNavigation();
  }

  setFeaturesCount(featuresCount: any) {
    this.featureCount.next(featuresCount);
  }

  getFeaturesCount(): BehaviorSubject<number> {
    return this.featureCount;
  }

  slideActiveTabForward(data?: any) {
    this.updateNavigationEvent({
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_NEXT,
      data,
    });
  }

  slideActiveTabBack(data?: any) {
    this.updateNavigationEvent({
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_PREV,
      data,
    });
  }

  slideActiveTabStart(data?: any) {
    const params = {
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_START,
      data,
    };

    /*
    if(resetIndex !== undefined) {
      params['resetIndex'] = resetIndex;
    }
    */

    this.updateNavigationEvent(params);
  }

  slideActiveTabEnd(data?: any) {
    this.updateNavigationEvent({
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_END,
      data
    });
  }

  getNavigationEvent(): BehaviorSubject<NavigationEvent> {
    return this.navigationEvent;
  }

  getSelectedFeature(): BehaviorSubject<Feature> {
    return this.selectedFeature;
  }

  updateSelectedFeature(feature: Feature) {
    this.selectedFeature.next(feature);
  }

  nextSelectedFeature() {
    this.updateNavigationEvent({
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_NEXT_FEATURE
    });
  }

  previousSelectedFeature() {
    this.updateNavigationEvent({
      target: NAVIGATION_SERVICE_CONSTANTS.TARGET_CAROUSEL_CONTENT,
      action: NAVIGATION_SERVICE_CONSTANTS.ACTION_PREV_FEATURE
    });
  }

  private updateNavigationEvent(navigationEvent: NavigationEvent) {
    this.navigationEvent.next(navigationEvent);
  }
}
