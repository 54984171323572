<div class="calculator-form row">
  <div class="col-md-7">
    <form [formGroup]="transportationForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">ROAD TRANSPORT</div>
        <p>Calculate and offset your transport emissions here:</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Transport Type</label>
        <select class="form-control" formControlName="type" (change)="changeVehicleType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let transportationType of transportationTypes"
            value="{{ transportationType.apiKey }}"
          >
            {{ transportationType.label }}
          </option>
        </select>
      </div>
      <div *ngIf="fuelTypeHasCountryData" class="form-group inline">
        <label class="col-form-label">Country</label>
        <select class="form-control" formControlName="source">
          <option
            *ngFor="let country of countries"
            value="{{ country }}"
          >
            {{ country }}
          </option>
        </select>
      </div>
      <div *ngIf="fuelTypes" class="form-group inline">
        <label class="col-form-label">Fuel Type</label>
        <select class="form-control" formControlName="fuelType" (change)="changeFuelType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let fuelType of fuelTypes"
            value="{{ fuelType.apiKey }}"
          >
            {{ fuelType.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Distance</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="distance"
        />
        <select class="form-control distance-unit" formControlName="distanceUnit">
          <option
            *ngFor="let distanceUnit of distanceUnits"
            value="{{ distanceUnit.apiKey }}"
          >
            {{ distanceUnit.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline passengers">
        <label for="journeys" class="col-form-label">No. of Journeys</label>
        <input
          type="number"
          min="1"
          class="form-control"
          name="journeys"
          formControlName="journeys"
        />
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="transportationForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>
