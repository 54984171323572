import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';

import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-climatecare-accommodations',
  templateUrl: './accommodations.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './accommodations.component.css' ]
})
export class ClimateCareAccommodationsComponent implements OnInit, FeatureComponent {

  data: any;

  accommodationsForm: FormGroup;

  accommodationTypes: Array<EmissionsOption>;

  accommodationLevels: Array<EmissionsOption>;

  countries: Array<string> = [];

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private appConfiguration: AppConfiguration;
  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.accommodationTypes = this.emissionsService.getFieldOptions('accommodationsTypes');

    this.accommodationLevels = this.accommodationTypes[0].units[0];

    this.accommodationsForm = this.formBuilder.group({
      type: [ this.accommodationTypes[0].apiKey, Validators.required ],
      accommodationType: [ this.accommodationLevels[0].apiKey, Validators.required ],
      guests: [ 0, [ Validators.required, Validators.min(1) ] ],
      nights: [ 0, [ Validators.required, Validators.min(1) ] ],
      source: [ this.countries[0] ]
    });

    this.appConfiguration.clientConfiguration.getCountries().
      subscribe((countries: Array<string>) => {
        this.countries = countries;
        this.accommodationsForm.patchValue({
          source: this.countries[0],
        });
    });

    this.accommodationsForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.accommodationsForm.valid) {
        this.getEmissions(form);
      }
    });
  }

  ngOnInit(): void {}

  changeAccommodationType(index: number) {
    this.accommodationLevels = this.accommodationTypes[index].units[0];
  }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const type = form['type'];
    const accommodationType = form['accommodationType'];
    const guests = form['guests'];
    const nights = form['nights'];
    const country = form['source'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          type,
          accommodationType,
          guests,
          nights,
          country
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_ACCOMMODATION
      );
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getAccommodationsEmissions(
      form['type'],
      form['accommodationType'],
      form['guests'],
      form['nights'],
      form['source']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.accommodationsForm.patchValue({
      type: this.accommodationTypes[0].apiKey,
      accommodationType: this.accommodationLevels[0].apiKey,
      guests: 0,
      nights: 0,
      source: this.countries[0]
    });
  }
}
