<div class="calculator-form row">
  <div class="col-md-7">
    <form id="eventForm" [formGroup]="eventForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">EVENTS</div>
        <p>Calculate and offset emissions from events here:</p>
      </div>
      <div class="form-group inline block-caption">
        <form [formGroup]="locationForm">
          <label class="col-form-label">Where was the event held?</label>
          <select class="form-control location" formControlName="location">
            <option *ngFor="let country of countries" value="{{ country }}">
              {{ country }}
            </option>
          </select>
        </form>
      </div>
      <div class="form-group inline block-caption">
        <label class="col-form-label">What type of venue, area, and duration (days)?</label>
        <form [formGroup]="venueForm" class="expandable-field">
          <select class="form-control venue-type" formControlName="venueType">
            <option *ngFor="let venueType of venueTypes" value="{{ venueType.apiKey }}">
              {{ venueType.label }}
            </option>
          </select>
          <input type="number" class="form-control distance" formControlName="venueArea" min="0"/>
          <div class="responsive-fields">
            <select class="form-control venue-unit" formControlName="venueUnit">
              <option *ngFor="let venueUnit of venueUnits" value="{{ venueUnit.apiKey }}">
                {{ venueUnit.label }}
              </option>
            </select>
            <input type="number" class="form-control distance" formControlName="eventDays" min="1"/>
          </div>
        </form>
      </div>
      <div class="form-group inline block-caption">
        <div class="expandable-field">
          <label class="col-form-label">How did people attend and how many?</label>
          <div class="responsive-fields">
            <div class="add-remove-button" (click)="addAttendees()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </div>
            <div class="add-remove-button" (click)="removeAttendees()" *ngIf="attendeesForms.length > 1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </div>
          </div>
        </div>
        <ng-container formArrayName="attendeesForms">
          <form *ngFor="let attendeesForm of attendeesForms.controls; let i=index" [formGroup]="attendeesForm" class="expandable-field">
            <input type="number" class="form-control attendees" formControlName="transporationAttendees" min="0"/>
            <select class="form-control" formControlName="transportationType" (change)="changeTransportationType($event.currentTarget.selectedIndex)">
              <option *ngFor="let eventTransporationType of eventTransporationTypes" value="{{ eventTransporationType.apiKey }}">
                {{ eventTransporationType.label }}
              </option>
            </select>
            <div class="responsive-fields">
              <select *ngIf="attendeesForm.get('transportationType').value === 'flight'" class="form-control" formControlName="flightRange">
                <option *ngFor="let flightRange of eventFlightRanges" value="{{ flightRange.apiKey }}">
                  {{ flightRange.label }}
                </option>
              </select>
              <select *ngIf="attendeesForm.get('transportationType').value === 'flight'" class="form-control flight-class" formControlName="flightClass">
                <option *ngFor="let flightClass of eventFlightClasses" value="{{ flightClass.apiKey }}">
                  {{ flightClass.label }}
                </option>
              </select>
            </div>
          </form>
        </ng-container>
      </div>
      <div class="form-group inline block-caption">
        <div class="expandable-field">
          <label class="col-form-label">What type of meals were provided?</label>
          <div class="responsive-fields">
            <div class="add-remove-button" (click)="addMeals()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </div>
            <div class="add-remove-button" (click)="removeMeals()" *ngIf="mealsForms.length > 1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </div>
          </div>
        </div>
        <ng-container formArrayName="mealsForms">
          <form *ngFor="let mealsForm of mealsForms.controls; let j = index" [formGroup]="mealsForm" class="expandable-field">
            <input type="number" class="form-control distance" formControlName="mealAmounts" min="0"/>
            <div class="responsive-fields">
              <select class="form-control meals" formControlName="mealType">
                <option *ngFor="let mealType of mealTypes" value="{{ mealType.apiKey }}">
                  {{ mealType.label }}
                </option>
              </select>
            </div>
          </form>
        </ng-container>
      </div>
      <div class="form-group inline block-caption">
        <div class="expandable-field">
          <label class="col-form-label">What number and types of rooms were provided?</label>
          <div class="responsive-fields">
            <div class="add-remove-button" (click)="addAccommodations()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </div>
            <div class="add-remove-button" (click)="removeAccommodations()" *ngIf="accomodationsForms.length > 1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </div>
          </div>
        </div>
        <ng-container formArrayName="accomodationsForms">
          <form *ngFor="let accommodationForm of accomodationsForms.controls; let k = index" [formGroup]="accommodationForm" class="expandable-field">
            <input type="number" class="form-control distance" formControlName="accommodationNights" min="0"/>
            <select class="form-control" formControlName="accommodationType">
              <option *ngFor="let accommodationType of accommodationTypes" value="{{ accommodationType.apiKey }}">
                {{ accommodationType.label }}
              </option>
            </select>
            <div *ngIf="hasAccommodationLevels(accommodationForm)" class="responsive-fields">
              <select class="form-control hotel-type" formControlName="accommodationLevel">
                <option *ngFor="let accommodationLevel of accommodationLevels" value="{{ accommodationLevel.apiKey }}">
                  {{ accommodationLevel.label }}
                </option>
              </select>
            </div>
          </form>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="internalForm"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>

<div *ngIf="errors.length" class="container-fluid p-0">
  <div *ngFor="let error of errors" class="error-message">
    <span class="fs-14 text-danger italic">{{ error }}</span>
  </div>
</div>
