<div class="calculator-form row">
  <div class="col-md-7">
    <form id="freightForm" [formGroup]="freightForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">FREIGHT</div>
        <p>Calculate emissions from shipping and delivery:</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Delivered by?</label>
        <select class="form-control" formControlName="class" (change)="changeClassType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let freightClassType of freightClassTypes"
            value="{{ freightClassType.apiKey }}"
          >
            {{ freightClassType.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label *ngIf="!freightClassHasTime" class="col-form-label">Distance</label>
        <label *ngIf="freightClassHasTime" class="col-form-label">Distance/Duration</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="distance"
        />
        <select class="form-control distance-unit" formControlName="unit">
          <option
            *ngFor="let freightDistanceUnit of freightDistanceUnits"
            value="{{ freightDistanceUnit.apiKey }}"
          >
            {{ freightDistanceUnit.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Weight</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="weight"
        />
        <select class="form-control distance-unit" formControlName="weightUnit">
          <option
            *ngFor="let weightUnit of weightUnits"
            value="{{ weightUnit.apiKey }}"
          >
            {{ weightUnit.label }}
          </option>
        </select>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="freightForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>

