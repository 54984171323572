import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { CartService } from '../../../shared/services/cart/cart.service';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { Feature, FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';

const EVENT_ADD_TO_BASKET = 'addToBasket';
const EVENT_GO_TO_CART = 'goToCart';

const ADD_TO_BASKET_CAPTION = 'ADD TO BASKET';
const ADDED_CAPTION = 'ADDED';

@Component({
  selector: 'app-purchase-emissions',
  templateUrl: './purchase-emissions.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './purchase-emissions.component.css' ]
})
export class PurchaseEmissionsComponent implements OnInit, FeatureComponent {

  data: any;

  @Input()
  emissionsItem: EmissionsItem;

  @Input()
  totalEmissions ?= 0;

  @Input()
  emissionsRequestActivated ?= false;

  @Input()
  emissionsRequestValid ?= false;

  @Output()
  purchaseAction: EventEmitter<EmissionsItem>;

  cartEmpty = true;

  showFeatureNavigation = true;

  enableNext = true;

  addedCaption;

  disableButton = false;

  postPurchaseButtonColor = '#879300';

  private cart: Cart;

  private featureCount: number;

  private feature: Feature;

  private addToBasketCaption: string;

  private addedToBasketCaption: string;

  constructor(private injector: Injector) {
    this.purchaseAction = new EventEmitter<EmissionsItem>();

    const translateService = this.injector.get(TranslateService);

    translateService.get('purchase-emissions.add-to-basket').subscribe((res: string) => {
      this.addToBasketCaption = res;
      this.addedCaption = this.addToBasketCaption;
    });
    translateService.get('purchase-emissions.added-to-basket').subscribe((res: string) => {
      this.addedToBasketCaption = res;
    });

    if (this.injector.get(AppConfiguration).clientConfiguration.clientSettings.postPurchaseButtonColor) {
      this.postPurchaseButtonColor = this.injector.get(AppConfiguration).clientConfiguration.clientSettings.postPurchaseButtonColor;
    }

    this.injector.get(EmissionsService).
      getEmissionsRequestActivated().subscribe((activated: boolean) => {
        this.emissionsRequestActivated = activated;
    });

    this.injector.get(NavigationService).
      getFeaturesCount().subscribe((featureCount: number) => {
        this.featureCount = featureCount;
        this.showFeatureNavigation = featureCount > 1;
    });

    this.injector.get(CartService).getCart().subscribe((cart: Cart) => {
      this.cart = cart;
      this.refreshNext();
    });

    this.injector.get(NavigationService).getSelectedFeature().subscribe((feature: Feature) => {
      this.feature = feature;
      this.refreshNext();
    });

  }

  ngOnInit() {}

  addToBasket() {
    this.addedCaption = '';
    this.disableButton = true;

    this.purchaseAction.emit(this.emissionsItem);

    setTimeout(
      () => {
        this.addedCaption = this.addedToBasketCaption;
        setTimeout(() => {
            this.addedCaption = this.addToBasketCaption;
            this.disableButton = false;
          },
          1000
        );
      },
      1000
    );
  }

  nextFeature() {
    this.injector.get(NavigationService).nextSelectedFeature();
  }

  previousFeature() {
    this.injector.get(NavigationService).previousSelectedFeature();
  }

  private refreshNext() {
    if (this.feature) {
      const seqn = parseInt(this.feature.ref.seqn);
      const lastFeatureIndex = (this.featureCount - 1);
      this.enableNext = seqn === lastFeatureIndex ? !!(this.cart.totalTonnes > 0) : true;
    }
  }

}
