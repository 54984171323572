import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalculatorApi } from './calculator-api.service';
import { Project } from './project.interface';

@Injectable()
export class CalculatorDataResolverService implements Resolve<{ projects: Array<Project> }> {

  constructor(private calculatorApi: CalculatorApi) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ projects: Array<Project> }> {
    return this.resolveCalculatorData();
  }

  private resolveCalculatorData() {
    return this.calculatorApi.getProjects(true).
        pipe(
          map((projects: Array<Project>) => {
            return { projects };
          })
        );
  }
}
