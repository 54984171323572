<div class="project-card-wrapper">
    <div class="project-card-img">
        <img [src]="splash">
    </div>
    <div class="project-card-title">{{ ('offset-projects.' + offsetProject + '.title')  | translate }}</div>
    <div class="project-card-contents">
        <div class="project-card-subtitle">
            <p [innerHTML]="('offset-projects.' + offsetProject + '.text')  | translate "></p>
        </div>
    </div>
    <div class="project-card-subtitle">
        <p>{{ project.quotedCurrency.symbol }} {{ project.quotedCostPerTonne | number:'1.2-2' }} {{ 'project-card.per-caption' | translate }} tCO<sub>2</sub>e</p>
    </div>
    <div class="project-card-button">
        <button (click)="selectProject()">{{ 'project-card.select-project-caption' | translate }}</button>
    </div>
</div>
