<div class="calculator-form-title">{{ 'offset-projects.header' | translate }}</div>
<div class="checkout-flow-wrapper">
  <div class="row">
    <div *ngFor="let project of projects" class="project-card col-sm-6">
      <app-project-card
        [project]="project"
        (chooseProject)="chooseProject($event)"
      ></app-project-card>
    </div>
  </div>
</div>
<div>
  <app-post-purchase-navigation
    [target]="data.id"
    [hasNext]="false"
    (back)="back()"
  >
  </app-post-purchase-navigation>
</div>
