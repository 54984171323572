<div class="checkout-flow-controls">
  <div class="voucher-wrapper">
    <form *ngIf="hasVoucherForm" [formGroup]="voucherForm">
      <div class="form-group inline">
        <label for="voucherCode" class="col-form-label">{{ 'customer-information.voucher-caption' | translate }}</label>
        <input (blur)="getVoucherCode()" type="text" class="form-control" name="voucherCode" formControlName="voucherCode"/>
      </div>
    </form>
  </div>
  <div class="buttons-wrapper">
    <div *ngIf="hasMiddle && payPalConfig" class="calculator-form-title-small choose-payment">{{ 'customer-information.payment-method-caption' | translate }}</div>
    <button class="back-button" (click)="clickBack()">{{ firstCaption }}</button>
    <div class="paypal" *ngIf="hasMiddle && payPalConfig && nextEnabled">
      <ngx-paypal [config]="payPalConfig" [registerScript]="false" #payPalButton></ngx-paypal>
    </div>
    <button
      *ngIf="hasNext"
      [ngClass]="{ disabled: !nextEnabled }"
      [disabled]="!nextEnabled"
      class="next-button"
      (click)="clickNext()">
      <div *ngIf="activated" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span *ngIf="!activated">
        {{ secondCaption }}
      </span>
    </button>
  </div>
</div>
