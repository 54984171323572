import { Component, Injector, Input, OnInit } from '@angular/core';

import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-additional-options',
  templateUrl: './additional-options.component.html',
  styleUrls: ['./additional-options.component.css']
})
export class AdditionalOptionsComponent implements OnInit, FeatureComponent {

  data: any;

  constructor(private injector: Injector) { }

  ngOnInit(): void {}

}
