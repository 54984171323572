import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.css']
})
export class TabHeaderComponent implements OnInit {

  @Input()
  featureComponents: Array<any>;

  @Input()
  navAsSelect = false;

  @Input()
  activeContent ?= 0;

  selectedColor;

  selectedTextColorClass;

  @Output()
  public selectFeature: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.updateSelectedColor();
  }

  changeFeature(index) {
    this.updateSelectedColor();
    this.selectFeature.emit(this.activeContent);
  }

  updateSelectedColor() {
    if (this.featureComponents) {
      this.selectedColor = (this.featureComponents[this.activeContent] as any).ref.theme.fillColor;
      this.selectedTextColorClass = (this.featureComponents[this.activeContent] as any).ref.theme.textClass;
    }
  }

}
