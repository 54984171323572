<form [formGroup]="detailsForm">
  <div class="calculator-form-title-small">{{ 'pay-offsets.credit-card-caption' | translate }}</div>
  <div id="card-element">
    <!-- Elements will create input elements here -->
  </div>
  <div *ngFor="let error of errors" id="card-errors" role="alert" class="text-danger">
    {{ error }}
  </div>
  <!-- <div class="form-group inline">
    <label for="customerName">Name on card</label>
    <div class="credit-card-input-wrapper">
      <input
        type="text"
        name="customerName"
        class="form-control credit-card-input credit-card-input-name"
        placeholder="Name"
        [formControl]="detailsForm.controls['customerName']"
        (keydown)="nameChanged($event)"
      />
    </div>
  </div>
  <div class="form-group inline">
    <label>Card Number</label>
    <div class="credit-card-input-wrapper">
      <div id="card-number" class="field empty credit-card-input"></div>
    </div>
    <div *ngIf="numberError">{{ numberError }}</div>
  </div>
  <div class="form-group inline">
    <label for="expiryDate">Expiry Date</label>
    <div class="credit-card-input-wrapper">
      <div id="card-expiry" class="field empty credit-card-input"></div>
    </div>
    <div *ngIf="expiryError">{{ expiryError }}</div>
  </div>
  <div class="form-group inline">
    <label for="cvv">CVC</label>
    <div class="credit-card-input-wrapper">
      <div id="card-cvc" class="field empty credit-card-input"></div>
    </div>
    <div *ngIf="cvcError">{{ cvcError }}</div>
  </div> -->
</form>
