import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabContentHeaderComponent } from '../tab-content-header/tab-content-header.component';

@NgModule({
  declarations: [
    TabContentHeaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabContentHeaderComponent
  ],
})
export class TabContentHeaderModule { }
