import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CartService } from '../../../shared/services/cart/cart.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-climatecare-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './transportation.component.css' ]
})
export class ClimateCareTransportationComponent implements OnInit, FeatureComponent {

  data: any;

  transportationForm: FormGroup;

  airports: Array<string>;

  transportationTypes: Array<EmissionsOption>;

  distanceUnits: Array<EmissionsOption>;

  fuelConsumptionUnits: Array<EmissionsOption>;

  countries: Array<string> = [];

  tonnes = 0;

  displayedTonnes = 0;

  fuelTypeHasCountryData = false;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  fuelTypes: Array<EmissionsOption>;

  private appConfiguration: AppConfiguration;
  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.transportationTypes = this.emissionsService.getFieldOptions('transportationTypes');
    this.distanceUnits = this.emissionsService.getFieldOptions('distanceUnits');
    this.fuelConsumptionUnits = this.emissionsService.getFieldOptions('fuelConsumptionUnits');

    this.transportationForm = this.formBuilder.group({
      type: [ this.transportationTypes[0].apiKey, Validators.required ],
      distance: [ 0, [ Validators.required, Validators.min(1) ] ],
      distanceUnit: [ this.distanceUnits[0].apiKey ],
      journeys: [ 1 ],
      fuelType: [ '' ],
      source: [ this.countries[0] ]
    });

    this.changeVehicleType(0);

    this.appConfiguration.clientConfiguration.getCountries().
      subscribe((countries: Array<string>) => {
        this.countries = countries;
        this.transportationForm.patchValue({
          source: this.countries[0],
        });
    });

    this.transportationForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.transportationForm.valid) {
        this.getEmissions(form);
      }
    });

  }

  ngOnInit(): void {}

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  changeVehicleType(index: number) {
    this.fuelTypes = this.transportationTypes[index].units &&
      this.transportationTypes[index].units.length &&
      this.transportationTypes[index].units[0];

    this.fuelTypeHasCountryData = false;

    this.transportationForm.patchValue({
      fuelType: (this.fuelTypes && this.fuelTypes[0].apiKey)
    });
  }

  changeFuelType(index: number) {
    this.fuelTypeHasCountryData = this.fuelTypes[index].hasCountryData;
  }

  private updateEmissions(tonnes: number, form: any): void {
    const type = form['type'];
    const fuelType = form['fuelType'] === 'electric'
      ? 'electricity' : form['fuelType'];
    const distance = form['distance'];
    const journeys = form['journeys'];
    const source = form['source'];

    let distanceUnit = form['distanceUnit'];

    if (distanceUnit === 'miles') {
      distanceUnit = distanceUnit.slice(0, -1);
    }

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          type,
          fuelType,
          distance,
          distanceUnit,
          journeys,
          source
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION_GENERIC
      );
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getTransportationEmissions(
      form['type'],
      form['distance'],
      form['distanceUnit'],
      form['journeys'],
      form['fuelType'],
      form['source']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.fuelTypeHasCountryData = false;
    this.transportationForm.patchValue({
      type: this.transportationTypes[0].apiKey,
      distance: 0,
      distanceUnit: this.distanceUnits[0].apiKey,
      journeys: 1,
      fuelType: (this.fuelTypes && this.fuelTypes[0].apiKey),
      source: this.countries[0]
    });
  }
}
