<app-tab-header
    [hidden]="!showNavigationMenu"
    [featureComponents]="featureComponents"
    [navAsSelect]="navAsSelect"
    [activeContent]="selectedIndex"
    (selectFeature)="selectFeature($event)">
</app-tab-header>
<app-tab-content *ngIf="footerComponents"
    [featureComponents]="featureComponents"
    [contentComponents]="contentComponents"
    [activeFeature]="selectedIndex">
</app-tab-content>
<app-tab-footer *ngIf="footerComponents"
    [components]="footerComponents">
</app-tab-footer>
