import { Component, Injector, OnInit } from '@angular/core';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './intro.component.css']
})
export class IntroComponent implements OnInit, FeatureComponent {

  data: any;

  arrow = 'arrow';

  checklist = 'checklist';

  basket = 'basket';

  showFooterCaption = true;

  constructor(private injector: Injector) {
    const appConfiguration: AppConfiguration = this.injector.get(AppConfiguration);

    if (appConfiguration.clientConfiguration.clientSettings.hideIntroFooterCaption) {
      this.showFooterCaption = false;
    }
  }

  ngOnInit(): void {}

  nextFeature() {
    this.injector.get(NavigationService).nextSelectedFeature();
  }

  previousFeature() {
    this.injector.get(NavigationService).previousSelectedFeature();
  }
}
