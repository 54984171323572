<div class="calculator-form row flights-type">
  <div class="col-md-7">
    <form [formGroup]="flightForm">
      <div class="calculator-form-header form-group">
        <div>{{ 'flights.header' | translate }}</div>
        <p>{{ 'flights.description' | translate }}</p>
      </div>

      <div class="form-group inline">
        <label for="departureAirport">{{ 'flights.departure-caption' | translate }}</label>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'flights.placeholder-caption' | translate }}"
          formControlName="departureAirport"
          [ngbTypeahead]="search"
          #instance="ngbTypeahead"
        />
      </div>
      <div class="form-group inline">
        <label for="connectionAirport">{{ 'flights.connection-caption' | translate }}</label>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'flights.placeholder-caption' | translate }}"
          formControlName="connectionAirport"
          [ngbTypeahead]="search"
          #instance="ngbTypeahead"
        />
      </div>
      <div class="form-group inline">
        <label for="destinationAirport">{{ 'flights.destination-caption' | translate }}</label>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'flights.placeholder-caption' | translate }}"
          formControlName="destinationAirport"
          [ngbTypeahead]="search"
          #instance="ngbTypeahead"
        />
      </div>
      <div *ngIf="!cargoSelected" class="form-group inline passengers">
        <label for="passengers" class="col-form-label">{{ 'flights.no-passengers-caption' | translate }}</label>
        <input
          id="passengers"
          type="number"
          min="1"
          class="form-control"
          placeholder="Passengers"
          formControlName="passengers">
      </div>
      <div *ngIf="cargoSelected" class="form-group inline cargo">
        <label for="cargo" class="col-form-label">{{ 'flights.tonnes-caption' | translate }}</label>
        <input
          id="cargo"
          type="number"
          min="1"
          class="form-control"
          placeholder="Tonnes"
          formControlName="tonnes">
      </div>
      <div class="form-group inline">
        <label for="flightClassInput" class="col-form-label">{{ 'flights.flight-class-caption' | translate }}</label>
        <select
          class="form-control"
          formControlName="class"
          (change)="changeFlightClassInput($event.currentTarget.value)">
          <option *ngFor="let flightClass of flightClasses" value="{{ flightClass.apiKey }}">
            {{ flightClass.label  | translate }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label for="flightType" class="col-form-label">{{ 'flights.flight-type-caption' | translate }}</label>
        <div *ngFor="let flightType of flightTypes; let i = index" class="flight-type form-check form-check-inline" style="display: inline-flex!important;">
          <input
            id="flight-type-radio-{{ i }}"
            class="form-check-input align-middle"
            type="radio"
            name="flightType"
            value="{{ flightType.apiKey }}"
            formControlName="flightType"
            [checked]="i == 0">
          <label
            id="flight-type-radio-{{ i }}"
            class="form-check-label"
            for="flight-type-radio-{{ flightType.key }}"
            style="width: auto; line-height: unset;display: contents;">
              {{ flightType.label  | translate }}
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestValid]="emissionsRequestValid"
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"></app-purchase-emissions>
  </div>
</div>

<div *ngIf="errors.length" class="container-fluid p-0">
  <div *ngFor="let error of errors" class="error-message">
    <span class="fs-14 text-danger italic">{{ error }}</span>
  </div>
</div>
