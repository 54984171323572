import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AirportService } from '../../../shared/services/airports/airport-service';
import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';

@Component({
  selector: 'app-climatecare-flights',
  templateUrl: './flights.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './flights.component.css' ]
})
export class ClimateCareFlightsComponent implements OnInit, FeatureComponent {

  data: any;

  flightForm: FormGroup;

  airports: Array<string>;

  flightClasses: Array<EmissionsOption>;

  flightTypes: Array<EmissionsOption>;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  cargoSelected = false;

  errors: Array<string> = [];

  private airportService: AirportService;
  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;
  private appConfiguration: AppConfiguration;

  constructor(private injector: Injector) {
    this.airportService = this.injector.get(AirportService);
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);
    this.appConfiguration = this.injector.get(AppConfiguration);

    this.airportService.getAirports().subscribe((airports) => {
      this.airports = airports;
    });

    this.flightTypes = this.emissionsService.getFieldOptions('flightTypes');
    this.flightClasses = this.emissionsService.getFieldOptions('flightClasses');

    const defaultClassIndex = this.resolveDefaultFlightClass()

    this.flightForm = this.formBuilder.group(
      {
        departureAirport: [ '', [ Validators.required ] ],
        destinationAirport: ['', [ Validators.required ] ],
        connectionAirport: [''],
        flightType: [ this.flightTypes[0].apiKey, Validators.required],
        class: [ this.flightClasses[defaultClassIndex].apiKey, Validators.required],
        passengers: [1, Validators.required],
        tonnes: [0],
      }
    );

    this.flightForm.valueChanges.subscribe(form => {
      if (this.preValidation(form)) {
        this.emissionsRequestValid = true;
        this.getEmissions(form);
      } else {
        this.emissionsRequestValid = false;
      }
    })
  }

  ngOnInit(): void {}

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map((term) =>
      term.length < 3 ? [] : this.concatenateSearchForICAOFirstSearch(term),
    ),
  );

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  changeFlightClassInput(value: string) {
    this.cargoSelected = (value === 'cargo' && this.isClient('chinaair')) ? true : false;
  }

  private updateEmissions(tonnes: number, form: any): void {
    const passengers = form['passengers'];
    const flightType = form['flightType'];
    const flightClass = form['class'];
    const departureCity = this.parseAirport(form['departureAirport'], 2);
    const connectionCity = form['connectionAirport'] ? this.parseAirport(form['connectionAirport'], 2) : undefined;
    const destinationCity = this.parseAirport(form['destinationAirport'], 2);

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          passengers,
          flightType,
          flightClass,
          departureCity,
          destinationCity,
          connectionCity
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION
      );
  }

  private resolveDefaultFlightClass() {
    let defaultClassIndex = 0

    if (this.isClient('chinaair')) {
      defaultClassIndex = 1
    }

    return defaultClassIndex
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getFlightEmissions(
      this.parseAirport(form['departureAirport'], 0),
      this.parseAirport(form['destinationAirport'], 0),
      form['flightType'],
      form['class'],
      form['passengers'],
      this.parseAirport(form['connectionAirport'], 0),
      form['tonnes']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private concatenateSearchForICAOFirstSearch(searchString: string) {
    const startsWith =  this.airports.filter((v) => v.toLowerCase().startsWith(searchString.toLowerCase()));
    const indexOf =  this.airports.filter((v) => v.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    return startsWith.concat(indexOf.filter(x => startsWith.every(y => y !== x))).slice(0, 10);
  }

  private adjustSearchForICAOFirstSearch(searchString: string) {
    return searchString.length === 3 ? searchString += ' ' : searchString
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.flightForm.patchValue({
        departureAirport: '',
        destinationAirport: '',
        connectionAirport: '',
        flightType: this.flightTypes[0].apiKey,
        class: this.flightClasses[0].apiKey,
        passengers: 1
      }
    );
  }

  private parseAirport(airport: string, token: number): string {
    const tokens = airport.split('-');
    return (tokens.length >= 4) ? tokens[token].trim() : null;
  }

  private preValidation(form: any): boolean {
    // TODO: This is a pre form validation - there current reactive form validation
    // is not behaving consistently do we have to validate here.  This should be removed
    // in the future in favor of using the native reactive form functionality
    let hasRequiredParams = !!((form['departureAirport'] && this.parseAirport(form['departureAirport'], 0)) &&
      (form['destinationAirport'] && this.parseAirport(form['destinationAirport'], 0)));

    if (form['connectionAirport']) {
      hasRequiredParams = hasRequiredParams && !!this.parseAirport(form['connectionAirport'], 0);
    }

    return hasRequiredParams;
  }

  private isClient(client: string) {
    let clientFound = false;

    const calcClient =
      this.appConfiguration.clientConfiguration.clientSettings.client;

    if (calcClient === client) {
      clientFound = true;
    }

    return clientFound;
  }
}
