import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { CartService } from '../../../shared/services/cart/cart.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { Project } from '../../../shared/services/calculator-api/project.interface';
import { ProductService } from '../../../shared/services/product/product.service';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';
import { VoucherCode } from '../../../shared/services/calculator-api/voucher-code.interface';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './cart.component.css']
})
export class CartComponent implements OnInit, FeatureComponent {

  data: any;

  cart: Cart;

  appConfiguration: AppConfiguration;

  calculatorApi: CalculatorApi;

  productService: ProductService;

  cartService: CartService;

  navigationService: NavigationService;

  paying = false;

  hasMinimum = false;

  hasShareControl = false;

  hasBasketHeaderCaption = false;

  customCartHeaderColor = '';

  expectedMinimum = 0;

  countries: Array<string> = [];

  selectedCountry = '';

  required = true;

  proceed = false;

  vatAmount = 0;

  vatForm: FormGroup;

  private project: Project;

  private share = false;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.calculatorApi = this.injector.get(CalculatorApi);
    this.cartService = this.injector.get(CartService);
    this.productService = this.injector.get(ProductService);
    this.navigationService = this.injector.get(NavigationService);

    this.cartService.getCart().subscribe((cart: Cart) => {
      this.cart = cart;
      this.hasMinimum = cart.hasMinimum;
    });

    this.injector.get(CalculatorApi).
      getSelectedProject().
      subscribe((project: Project) => {
        this.project = project;
      });

    this.hasShareControl = this.appConfiguration.clientConfiguration.clientSettings.hasShareControl;
    this.customCartHeaderColor = this.appConfiguration.clientConfiguration.clientSettings.customCartHeaderColor;
    this.hasBasketHeaderCaption = this.appConfiguration.clientConfiguration.clientSettings.hasBasketHeaderCaption;
    this.expectedMinimum  = this.cartService.getExpectedMinimum(this.cart);
    this.appConfiguration.clientConfiguration.getCountries().
        subscribe((countries: Array<string>) => {
          this.countries = countries; 
        });

    this.selectedCountry = this.cartService.vatCountries[0];
    this.vatForm = this.injector.get(FormBuilder).group({vatCountry: [''],vatAddress: ['']});
    this.vatForm.setValue({ vatCountry: this.selectedCountry,vatAddress: ''});
  }

  ngOnInit(): void {}

  next(): void {

    this.cartService.updateVatAddress(this.vatForm.controls['vatAddress'].value);
        
    this.injector.get(NavigationService).slideActiveTabForward({
      showNavigationMenu: false,
      showCheckout: false,
      showCurrencySelector: false,
      showDefaultHeader: true,
      isStart: false
    });
  }

  back(): void {
    const navigationService = this.injector.get(NavigationService);
    navigationService.slideActiveTabBack(
      {
        showNavigationMenu: false,
        showCheckout: false,
        showCurrencySelector: true,
        showDefaultHeader: true,
        isStart: false
      }
    );
  }

  removeItem(index) {
    this.cartService.removeItem(index);

    if (this.cartService.isEmpty()) {
      this.navigationService.slideActiveTabStart({
        showNavigationMenu: true,
        showCheckout: false,
        showCurrencySelector: true,
        showDefaultHeader: true,
        isStart: true
      });
    }
  }

  voucherCode(voucherCode: VoucherCode) {
    this.cartService.addItem({ voucherCode });
  }

  changeCountry(value:string){
    this.addVat(value);
    
    this.selectedCountry = value;
    
    this.vatForm.setValue({ vatCountry: this.selectedCountry, vatAddress: ''});

    this.required = this.cartService.vatCountries.includes( this.selectedCountry );
    this.proceed = !this.cartService.vatCountries.includes( this.selectedCountry );
  }

  changeAddress(value:string) {
    const required = this.cartService.vatCountries.includes(this.selectedCountry);

    if(required){
      this.required = !(value.length > 3); 
      this.proceed = (value.length > 3);
    } else {
      this.required = required;
      this.proceed = !required;
    }

  }

  toggleShare() {
    this.share = !this.share;
    this.cart.canShare = this.share;
    this.cartService.updateCart(this.cart);
  }

  private addVat(value : string) {
      let amount = this.cartService.vatAmountPercentage;
      let address = this.vatForm.controls['vatAddress'].value;

      if(!this.cartService.vatCountries.includes(value)){
          amount =  0;
      }
      const vat = {country : value, address : address, amount: amount };
      this.cartService.addItem({ vat });  
  }
}
