import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-climatecare-start',
  templateUrl: './start.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './start.component.css']
})
export class ClimateCareStartComponent implements OnInit, FeatureComponent {

  data: any;

  tonnes = 0;

  cost = 0;

  displayedTonnes = 0;

  emissionsItem: EmissionsItem;

  emissionsRequestActivated = false;

  validOffset = false;

  startForm: FormGroup;

  private appConfiguration: AppConfiguration;

  private emissionsService: EmissionsService;

  private cartService: CartService;

  private calculatorApi: CalculatorApi;

  private currency: any;

  private cart: Cart;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.emissionsService = this.injector.get(EmissionsService);
    this.calculatorApi = this.injector.get(CalculatorApi);

    const formBuilder: RxFormBuilder = this.injector.get(RxFormBuilder);

    this.startForm = formBuilder.group({
      emissions: [
        this.tonnes,
        [
          RxwebValidators.required(
            {
              conditionalExpression: (form => {
                return !form.emissionsPerUnitCost.valid;
              })
            }
          )
        ]
      ],
      emissionsPerUnitCost: [
        this.cost,
        [
          RxwebValidators.required(
            {
              conditionalExpression: (form => {
                return !form.emissions.valid;
              })
            }
          )
        ]
      ]
    });

    this.startForm.valueChanges.subscribe((form: any) => {
      if (form.emissionsPerUnitCost) {
        this.tonnes = 0;
        this.displayedTonnes = 0;
      }
      if (form.emissions) {
        this.cost = 0;
      }

      this.validOffset =
        this.startForm.valid && (form.emissions > 0 || form.emissionsPerUnitCost > 0);
    });

    this.cartService.getCart().subscribe((cart: Cart) => {
      this.cart = cart;
      this.getEmissionsFromCost();
    });


    this.appConfiguration.clientConfiguration.
      getSelectedCurrency().subscribe((currency: any) => {
        this.currency = currency;
      });
  }

  ngOnInit(): void { }

  updateTonnes(tonnes: number): void {
    if (!tonnes) {
      this.tonnes = 0;
    }

    this.displayedTonnes = this.tonnes;

    if (this.tonnes > 0) {
      this.updateEmissions(this.tonnes);
    }
  }

  updateCost(cost: number): void {
    if (!cost) {
      this.cost = 0;
    }

    if (this.cost > 0) {
      this.getEmissionsFromCost();
    }
  }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number): void {
    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem({ tonnes }, EmissionsItemCatalog.OFFSET_CATEGORY_GENERAL);
  }

  private getEmissionsFromCost() {
    if (this.cart.costPerTonne) {
      const tonnes = this.cost / this.cart.costPerTonne;
      this.updateEmissions(tonnes);
    }
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.cost = 0;
  }
}
