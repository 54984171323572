<div class="calculator-form row">
  <div class="col-md-7">
    <form [formGroup]="doorStepsForm">
      <div>
        <div class="clearfix float-my-children">
          <img src="../../../../assets/img/climatecare/door-drops.png" />
          <div class="text-content">
            A flexible and cost-effective way to get your message across. Door drops work for businesses of all shapes and sizes – and for both local and large-scale campaigns.
          </div>
       </div>
      </div>
      <div class="form-group">
        <label for="small">Number of Items:</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="drops"/>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="doorStepsForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>
