import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexStripeCardComponent } from './flex-stripe-card.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner..module';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FlexStripeCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoadingSpinnerModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    FlexStripeCardComponent
  ]
})
export class FlexStripeCardModule {}


