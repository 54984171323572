import { Component, OnInit } from '@angular/core';

import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-climatecare-refrigeration',
  templateUrl: './refrigeration.component.html',
  styleUrls: ['./refrigeration.component.css']
})
export class ClimateCareRefrigerationComponent implements OnInit, FeatureComponent {

  data: any;

  constructor() { }

  ngOnInit(): void { }

}
