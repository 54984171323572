import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, QueryList, Type, ViewChildren } from '@angular/core';
import { FeatureComponent } from '../../../../features/calculators/feature-component-registry/feature-component.interface';
import { NavigationEvent } from '../../../../shared/services/navigation/navigation-event.interface';
import { NavigationService } from '../../../../shared/services/navigation/navigation.service';
import { TabContentHostDirective } from '../tab-content/tab-content-host.directive';

export const NAVIGATION_ACTION_NEXT = 'next';
export const NAVIGATION_ACTION_PREV = 'prev';
export const NAVIGATION_ACTION_START = 'start';
export const NAVIGATION_ACTION_END = 'end';

@Component({
  selector: 'app-tab-carousel',
  templateUrl: './tab-carousel.component.html',
  styleUrls: ['./tab-carousel.component.css']
})
export class TabCarouselComponent implements OnInit, AfterViewInit {

  @Input()
  id: string;

  @Input()
  components: Array<{ ref: any, component: Type<any> }>;

  @Input()
  featureComponents: Array<{ ref: any, component: Type<any> }>;

  @Input() set activeFeature(value: number) {
    this.activeFeatureInternal = value;
    this.updateFeatureComponent();
  }

  private activeFeatureInternal: number;

  @ViewChildren(TabContentHostDirective)
  appTabContentHosts: QueryList<TabContentHostDirective>;

  private componentsResolved = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.id = 'app-tab-carousel-' + this.id;

    this.navigationService.getNavigationEvent().subscribe((navigationEvent: NavigationEvent) => {
      this.handleNavigationEvent(navigationEvent);
    });
  }

  ngAfterViewInit(): void {
    this.resolveComponents();
    this.updateFeatureComponent();
  }

  prev(): void {
    ($('#' + this.id + '.carousel') as any).carousel('prev');
  }

  next(): void {
    ($('#' + this.id + '.carousel') as any).carousel('next');
  }

  start(): void {
    ($('#' + this.id + '.carousel') as any).carousel(0);
  }

  end(): void {
    ($('#' + this.id + '.carousel') as any).carousel(this.appTabContentHosts.length - 1);
  }

  private resolveComponents(): void {
    if (!this.componentsResolved) {
      this.appTabContentHosts.forEach((tabContentHostDirective: TabContentHostDirective, index: number) => {
        tabContentHostDirective.viewContainerRef.clear();

        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(this.components[index].component);

        const componentRef =
          tabContentHostDirective.viewContainerRef.createComponent<FeatureComponent>(componentFactory);

        const ref = this.components[index].ref;

        if (typeof ref === 'string' || ref instanceof String) {
          componentRef.instance.data = { id: ref };
        } else {
          componentRef.instance.data = { id: ref.component.templateName };
        }

        Object.assign(
          componentRef.instance.data,
          this.components[index].ref
        );
      });

      this.componentsResolved = true;
    }
  }

  private updateFeatureComponent() {
    if (this.appTabContentHosts) {
      const hostDirective = this.appTabContentHosts.first;

      hostDirective.viewContainerRef.clear();

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.featureComponents[this.activeFeatureInternal].component);

      const componentRef =
        hostDirective.viewContainerRef.createComponent<FeatureComponent>(componentFactory);

      componentRef.instance.data =
        this.featureComponents[this.activeFeatureInternal].ref;
    }
  }

  private handleNavigationEvent(navigationEvent: NavigationEvent): void {
    if (navigationEvent.target === this.id) {
      switch (navigationEvent.action) {
        case NAVIGATION_ACTION_NEXT:
          this.next();
          break;
        case NAVIGATION_ACTION_PREV:
          this.prev();
          break;
        case NAVIGATION_ACTION_START:
          this.start();
          break;
        case NAVIGATION_ACTION_END:
            this.end();
            break;
      }
    }
  }
}
