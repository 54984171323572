import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationComponent } from './confirmation.component';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';
import { SharedOffsetsModule } from '../../../shared/components/share-offsets/share-offsets.module';

@NgModule({
  declarations: [
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    SharedOffsetsModule,
    PostPurchaseNavigationModule,
    TranslateModule
  ],
  exports: [
    ConfirmationComponent
  ]
})
export class ConfirmationModule { }
