import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-offsets',
  templateUrl: './share-offsets.component.html',
  styleUrls: ['./share-offsets.component.css']
})
export class ShareOffsetsComponent implements OnInit {

  @Input()
  tonnes: number;

  @Input()
  siteUrl: string;

  @Input()
  shareMessage: string;

  sharedLinks = [
    { platform: 'linkedin' },
    { platform: 'facebook' },
    { platform: 'twitter' }/*,
    { platform: 'pinterest' },
    { platform: 'whatsapp' },*/
  ];

  constructor() {}

  ngOnInit(): void {}
}
