<div class="calculator-form row">
  <div class="col-md-7">
    <form [formGroup]="partiallyAddressedForm">
      <div>
        <div class="clearfix float-my-children">
          <img src="../../../../assets/img/climatecare/partial-address-mail.png" />
          <div class="text-content">
            Reach relevant new audiences, even if you don’t have a rich database. Partially addressed mail uses postcode data to target the right audience for your brand.
          </div>
       </div>
      </div>
      <div class="form-group">
        <label for="small">Number of Postcards/Small Letters:</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="small"/>
      </div>
      <div class="form-group">
        <label for="large">Number of Large Letters/Brochures/Magazines:</label>
        <input
          type="number"
          min="1"
          class="form-control"
          name="large"
          formControlName="large"
        />
      </div>
      <div class="bottom-message">
        <div class="text-content">
          Small Letters are items up to 100g and no larger than 240mm x 165mm x 5mm.  Large Letters are items up to 750g and no larger than 353mm x 250mm x 25mm.
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="partiallyAddressedForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>
