import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CartService } from '../../../shared/services/cart/cart.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-door-drops',
  templateUrl: './door-drops.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './door-drops.component.css']
})
export class DoorDropsComponent implements OnInit, FeatureComponent {
  data: any;

  doorStepsForm: FormGroup;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.doorStepsForm = this.formBuilder.group({
      drops: [ 0 ]
    });

    this.doorStepsForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.doorStepsForm.valid) {
        this.getEmissions(form);
      }
    });

  }

  ngOnInit(): void { }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const drops = form['drops'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          drops
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_DROPS
      );
  }

  private getEmissions(form: any) {
    const mailEmissions = [
      { "type": "doorDrops", "category": "all", "amount": form['drops'] }
    ];

    this.emissionsRequestActivated = true;
    this.emissionsService.getMailEmissions(mailEmissions)
      .subscribe((tonnes: number) => {
        this.emissionsRequestActivated = false;
        this.updateEmissions(tonnes, form);
      });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.doorStepsForm.patchValue({
      drops: 0
    });
  }
}
