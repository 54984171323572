import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PostMessage } from './post-message';

@Injectable()
export class PostMessageService {

    postMessage: BehaviorSubject<PostMessage>;

    private txOrigins = [];
    private rxOrigins = [];

    constructor() {
        this.postMessage = new BehaviorSubject<PostMessage>(null);

        window.addEventListener(
            'message',
            (message: any) => {
                if (this.canRx(message)) {
                    this.rx(message.data);
                }
            }
        );
    }

    setRxOrigins(origins: Array<string>) {
        this.rxOrigins = origins;
    }

    setTxOrigins(origins: Array<string>) {
        this.txOrigins = origins;
    }

    tx(message: any): boolean {
        if (top !== window.self && this.canTx()) {
            this.txOrigins.forEach(target => {
                top.postMessage(message, target);
            });
            return true;
        }
        return false;
    }

    private rx(postMessage: PostMessage): void {
        this.postMessage.next(postMessage);
    }

    private canTx() {
        /* Only send a message if we are inside an iframe */
        return top !== window.self;
    }

    private canRx(message: any) {
        return message.data &&
            message.data.event &&
            message.origin &&
            this.rxOrigins.indexOf(message.origin) !== -1;
    }
}


