import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseEmissionsModule } from '../purchase-emissions/purchase-emissions.module';
import { BusinessMailComponent } from './business-mail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BusinessMailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PurchaseEmissionsModule
  ],
  exports: [
    BusinessMailComponent
  ]
})
export class BusinessMailModule { }
