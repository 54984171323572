import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { startCase } from 'lodash';

import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-climatecare-energy',
  templateUrl: './energy.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './energy.component.css' ]
})
export class ClimateCareEnergyComponent implements OnInit, FeatureComponent {

  data: any;

  energyForm: FormGroup;

  airports: Array<string>;

  energyTypes: Array<EmissionsOption>;

  energyUnits: Array<EmissionsOption>;

  countries: Array<string> = [];

  energyTypeHasCountryData = true;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private appConfiguration: AppConfiguration;
  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.energyTypes = this.emissionsService.getFieldOptions('energyTypes');
    this.energyUnits = this.energyTypes[0].units[0];

    this.energyForm = this.formBuilder.group({
      type: [ this.energyTypes[0].apiKey, Validators.required ],
      consumption: [ 0, [ Validators.required, Validators.min(1) ] ],
      unit: [ this.energyUnits[0].apiKey ],
      source: [ this.countries[0] ],
      isRenewable: [ false ],
    });

    this.appConfiguration.clientConfiguration.getCountries().
      subscribe((countries: Array<string>) => {
        this.countries = countries;
        this.energyForm.patchValue({
          source: this.countries[0],
        });
    });

    this.energyForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.energyForm.valid) {
        this.getEmissions(form);
      }
    });
  }

  ngOnInit(): void {}

  changeEnergyType(index: number) {
    this.energyUnits = this.energyTypes[index].units[0];
    this.energyTypeHasCountryData = this.energyTypes[index].hasCountryData;
    this.energyForm.patchValue({
      unit: this.energyUnits[0].apiKey
    });
  }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const energyType = startCase(form['type']);
    const amount = form['consumption'];
    const unit = form['unit'];
    const country = form['type'] === 'electricity' ? form['source'] : '';
    const isRenewable = form['isRenewable'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          energyType,
          amount,
          unit,
          country,
          isRenewable
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_ENERGY
      );
  }

  private getEmissions(form: any) {
    if (!form.isRenewable) {
      this.emissionsRequestActivated = true;
      this.emissionsService.getEnergyEmissions(
        form['type'],
        form['consumption'],
        form['unit'],
        form['source'],
      ).subscribe((tonnes: number) => {
        this.emissionsRequestActivated = false;
        this.updateEmissions(tonnes, form);
      });
    } else {
      this.updateEmissions(0, form);
    }
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.changeEnergyType(0);
    this.energyForm.patchValue({
      type: this.energyTypes[0].apiKey,
      consumption: 0,
      unit: this.energyUnits[0].apiKey,
      source: this.countries[0]
    });
  }
}
