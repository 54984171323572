import { Component, Injector, OnInit } from '@angular/core';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-icroa-footer',
  templateUrl: './icroa-footer.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './icroa-footer.component.css' ]
})
export class IcroaFooterComponent implements OnInit, FeatureComponent {

  data: any;

  termsAndConditionsLink = 'http://www.climateimpact.com/business-solutions/small-business-calculator/carbon-calculator-terms-and-conditions/';

  logoClass = 'icroa-rev';

  constructor(private injector: Injector) {
    const appConfiguration: AppConfiguration = this.injector.get(AppConfiguration);
    const tcLink =
      appConfiguration.clientConfiguration.clientSettings.termsAndConditionsLink;

    if (appConfiguration.clientConfiguration.clientSettings.footerLogoClass) {
      this.logoClass = appConfiguration.clientConfiguration.clientSettings.footerLogoClass;
    }

    if (tcLink) {
      this.termsAndConditionsLink = tcLink;
    }
  }

  ngOnInit(): void {}

}
