export const EMISSIONS_SERVICE_CONSTANTS = {
  emissionTypes: {
    EMISSIONS_TYPE_EMISSIONS_PER_UNIT_COST: 'emissionsPerUnitCost',
    EMISSIONS_TYPE_FLIGHT: 'flight',
    EMISSIONS_TYPE_TRANSPORATION: 'transportation',
    EMISSIONS_TYPE_DIET: 'diet',
    EMISSIONS_TYPE_FREIGHT: 'freight',
    EMISSIONS_TYPE_EVENT: 'event',
    EMISSIONS_TYPE_ELECTRICITY: 'electricity',
    EMISSIONS_TYPE_WASTE: 'waste',
    EMISSIONS_TYPE_TRAVEL: 'travel',
    EMISSIONS_TYPE_HOTEL: 'hotel',
    EMISSIONS_TYPE_MAIL: 'mail'
  }
};
