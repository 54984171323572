import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, QueryList, Type, ViewChildren } from '@angular/core';
import { FeatureComponent } from '../../../../features/calculators/feature-component-registry/feature-component.interface';
import { TabContentHostDirective } from '../tab-content/tab-content-host.directive';

@Component({
  selector: 'app-tab-footer',
  templateUrl: './tab-footer.component.html',
  styleUrls: ['./tab-footer.component.css']
})
export class TabFooterComponent implements OnInit, AfterViewInit {

  @Input()
  components: Array<{ ref: any, component: Type<any> }>;

  @ViewChildren(TabContentHostDirective)
  appTabContentHosts: QueryList<TabContentHostDirective>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.resolveComponents();
  }

  resolveComponents() {
    this.appTabContentHosts.forEach((tabContentHostDirective: TabContentHostDirective, index: number) => {
      tabContentHostDirective.viewContainerRef.clear();

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(this.components[index].component);

      tabContentHostDirective.viewContainerRef.createComponent<FeatureComponent>(componentFactory);

    });
  }


}
