import { Component, Injector, OnInit } from '@angular/core';

import { CartService } from '../../../shared/services/cart/cart.service';
import { ExchangeRate } from '../../../shared/services/product/exchange-rate.interface';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { Project } from '../../../shared/services/calculator-api/project.interface';
import { ProductService } from '../../../shared/services/product/product.service';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';

@Component({
  selector: 'app-offset-projects',
  templateUrl: './offset-projects.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './offset-projects.component.css']
})
export class OffsetProjectsComponent implements OnInit, FeatureComponent {

  data: any;

  projects: Array<Project>;

  appConfiguration: AppConfiguration;

  cartService: CartService;

  navigationService: NavigationService;

  calculatorApi: CalculatorApi;

  productService: ProductService;

  currency: any;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.navigationService = this.injector.get(NavigationService);
    this.productService = this.injector.get(ProductService);
    this.calculatorApi = this.injector.get(CalculatorApi);
    this.initCurrency();
  }

  ngOnInit(): void {
    this.calculatorApi.getProjects().subscribe((projects: Array<Project>) => {
      this.projects = projects;
    });
  }

  chooseProject(project: Project) {
    this.cartService.setCostProperties(
      project.quotedCostPerTonne,
      project.quotedCurrency
    );
    this.calculatorApi.setSelectedProject(project);

    this.navigationService.slideActiveTabForward({
      showNavigationMenu: false,
      showCheckout: false,
      showCurrencySelector: true,
      showDefaultHeader: true,
      isStart: false
    });
  }

  back(): void {
    this.calculatorApi.clearSelectedProject();

    this.navigationService.slideActiveTabBack({
      showNavigationMenu: true,
      showCheckout: true,
      showCurrencySelector: false,
      showDefaultHeader: true,
      isStart: true
    });
  }

  private initCurrency(): void {
    this.appConfiguration.clientConfiguration.getSelectedCurrency().subscribe((currency: any) => {
      if (this.projects && this.projects.length) {
        const baseCurrency = this.projects[0].baseCurrency;

        this.productService.getFxRate(
          baseCurrency.iso2,
          currency.iso2
        ).subscribe((exchangeRate: ExchangeRate) => {
          this.projects.forEach((project: Project) => {
            project.quotedCurrency = currency;
            project.quotedCostPerTonne = project.costPerTonne * exchangeRate.rate;
          });
        });
      }
    });
  }
}
