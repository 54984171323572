import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomerDetailsComponent } from '../customer-details/customer-details.component';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';
import { CurrencySymbolModule } from '../../../shared/pipes/currency-symbol/currency-symbol.module';

@NgModule({
  declarations: [
    CustomerDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PostPurchaseNavigationModule,
    CurrencySymbolModule,
    TranslateModule
  ],
  exports: [
    CustomerDetailsComponent
  ]
})
export class CustomerDetailsModule { }
