import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClimateCareEventComponent } from './event.component';
import { OffsetInputModule } from '../../../shared/components/offset-input/offset-input.module';
import { PurchaseEmissionsModule } from '../purchase-emissions/purchase-emissions.module';
import { NgxBootstrapIconsModule, plusCircle, dashCircle } from 'ngx-bootstrap-icons';

@NgModule({
  declarations: [
    ClimateCareEventComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    OffsetInputModule,
    ReactiveFormsModule,
    PurchaseEmissionsModule,
    NgxBootstrapIconsModule.pick({ plusCircle, dashCircle })
  ],
  exports: [
    ClimateCareEventComponent
  ],
})
export class ClimateCareEventModule {
}
