import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdditionalOptionsComponent } from './additional-options.component';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';

@NgModule({
  declarations: [
    AdditionalOptionsComponent
  ],
  imports: [
    CommonModule,
    PostPurchaseNavigationModule
  ],
  exports: [
    AdditionalOptionsComponent
  ]
})
export class AdditionalOptionsModule { }
