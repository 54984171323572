import { Injectable } from '@angular/core';

import { environment } from './environment';
import { ClimateCareAppConfiguration } from '../../clients/climatecare/climatecare.configuration';
import { BehaviorSubject } from 'rxjs';

export const CLIENT_CPS = 'cps';
export const CLIENT_INSIGHT = 'insight';
export const CLIENT_CLIMATECARE = 'climatecare';
export const CLIENT_BCG = 'bcg';
export const CLIENT_ROYAL = 'royal';

@Injectable()
export class AppConfiguration {

  appReady: BehaviorSubject<boolean>;

  partnerConfig: any;

  clientConfiguration: any;

  tokenKey: string;

  userKey: string;

  organizationKey: string;

  constructor() {

    const seed = 263069594;

    const client = (environment as any).client;

    this.tokenKey = (environment as any).client + '-' + seed;
    this.userKey = (environment as any).client + '-' + (seed + 1);
    this.organizationKey = (environment as any).client + '-' + (seed + 2);

    this.clientConfiguration = new ClimateCareAppConfiguration((environment as any));

    this.appReady = new BehaviorSubject<boolean>(false);
  }

  updateAppReady(appReady: boolean): void {
    return this.appReady.next(appReady);
  }

  getAppReady(): BehaviorSubject<boolean> {
    return this.appReady;
  }

  hasCerts(): boolean {
    const client = (environment as any).client;

    if (client !== CLIENT_INSIGHT) {
      return false;
    }

    return true;
  }

}
