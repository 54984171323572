<div class="icroa-footer calculator-row row">
    <div class="col-sm-4">
        <div [ngClass]="logoClass"></div>
    </div>
    <div class="col-sm-3">
        <div class="icroa-text">+44 (0) 1865 591 000</div>
        <div class="icroa-text">
            <a href="mailto:info@climateimpact.com">info@climateimpact.com</a>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="icroa-text">
            <a href="{{ termsAndConditionsLink }}" target="_blank">{{ 'icroa-footer.terms-and-conditions' | translate }}</a>
        </div>
        <div class="icroa-text">
            <a href="http://www.climateimpact.com/business-solutions/small-business-calculator/calculator-methodology/" target="_blank">{{ 'icroa-footer.methodology' | translate }}</a>
        </div>
    </div>
    <div class="col-sm-3">
        <a href="https://www.co2analytics.com" target="_blank">
          <!-- <div class="co2analytics"></div> -->
          <img class="co2analytics" src="../../../../assets/img/climatecare/CA_Logo_WHITE.png" />
        </a>
    </div>
</div>
