import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';

@Injectable()
export class AirportService {

  private airports: Array<any>;

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration) { }

  public getAirports(): Observable<Array<any>> {
    if (this.airports) {
      return of(this.airports);
    }

    return this.httpClient.get<Array<any>>(
      this.appConfiguration.clientConfiguration.endpoints.getAirports,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((airports: Array<any>) => {
        this.airports = airports.map(v => v.airport);
        return this.airports;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}
