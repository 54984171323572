<div class="calculator-form row">
  <div class="col-md-7">
    <form id="wasteForm" [formGroup]="wasteForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">WASTE</div>
        <p>Calculate and offset emissions from waste here:</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Waste Type</label>
        <select class="form-control" formControlName="type" (change)="changeWasteType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let wasteType of wasteTypes"
            value="{{ wasteType.apiKey }}"
          >
            {{ wasteType.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Amount</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="weight"
        />
        <select class="form-control distance-unit" formControlName="unit">
          <option
            *ngFor="let wasteUnitType of wasteUnitTypes"
            value="{{ wasteUnitType.apiKey }}"
          >
            {{ wasteUnitType.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Disposal</label>
        <select class="form-control" formControlName="endOfLife">
          <option
            *ngFor="let wasteEolType of wasteEolTypes"
            value="{{ wasteEolType.apiKey }}"
          >
            {{ wasteEolType.label }}
          </option>
        </select>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="wasteForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>

<div *ngIf="errors.length" class="container-fluid p-0">
  <div *ngFor="let error of errors" class="error-message">
    <span class="fs-14 text-danger italic">{{ error }}</span>
  </div>
</div>
