import { Component, OnInit, Type, ViewChild } from '@angular/core';

import { AppConfiguration } from '../../bootstrap/climatecare/app.configuration';
import { CalculatorApi } from '../../shared/services/calculator-api/calculator-api.service';
import { Cart } from '../../shared/services/cart/cart.interface';
import { CartService } from '../../shared/services/cart/cart.service';
import { Feature } from '../../features/calculators/feature-component-registry/feature-component.interface';
import { FeatureComponentRegistryService } from '../../features/calculators/feature-component-registry/feature-component-registry.service';
import { NavigationEvent } from '../../shared/services/navigation/navigation-event.interface';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { NAVIGATION_SERVICE_CONSTANTS } from '../../shared/services/navigation/navigation.constants';
import { Project } from '../../shared/services/calculator-api/project.interface';
import { ProductService } from '../../shared/services/product/product.service';
import { ExchangeRate } from '../../shared/services/product/exchange-rate.interface';
import { Tab } from '../../shared/components/tabbed-container/tab.model';
import { TabbedContainerComponent } from '../../shared/components/tabbed-container/tabbed-container.component';

@Component({
  selector: 'app-climatecare',
  templateUrl: './climatecare.component.html',
  styleUrls: ['../../../assets/css/climatecare/app.css', './climatecare.component.css']
})
export class ClimateCareComponent implements OnInit {
  @ViewChild(TabbedContainerComponent) container!: TabbedContainerComponent;

  tab: Tab;

  navAsSelect = false;

  showNavigationMenu = true;

  showCheckout = false;

  showCurrencySelector = false;

  showDefaultHeader = true;

  totalTonnes = 0;

  currencies = [];

  logoImg = '';

  defaultTabIndex = '';

  featureComponents: Array<{ ref: any, component: Type<any> }>;

  private isCartEmpty = true;

  private isStart = true;

  private project: Project;

  constructor(
    private appConfiguration: AppConfiguration,
    private cartService: CartService,
    private calculatorApi: CalculatorApi,
    private featureComponentRegistryService: FeatureComponentRegistryService,
    private productService: ProductService,
    private navigationService: NavigationService) {

    this.createFeatureComponents();

    this.currencies =
      this.appConfiguration.clientConfiguration.payableCurrencies;

    this.logoImg =
      this.appConfiguration.clientConfiguration.clientSettings.logoImg ?
      this.appConfiguration.clientConfiguration.clientSettings.logoImg :
      this.appConfiguration.clientConfiguration.defaultLogoImg;

    this.defaultTabIndex =
      this.appConfiguration.clientConfiguration.clientSettings.defaultTabIndex ?
      this.appConfiguration.clientConfiguration.clientSettings.defaultTabIndex :
      0;

    this.cartService.getCart().subscribe((cart: Cart) => {
      this.isCartEmpty = cart.items.length === 0;
      this.totalTonnes = cart.totalTonnes;
      this.showCheckout = !this.isCartEmpty && this.isStart;
    });

    this.navigationService.getSelectedFeature().subscribe((feature: Feature) => {
      this.showCheckout = feature && feature.ref.name !== 'intro' && !this.isCartEmpty;
      this.showCurrencySelector = feature && feature.ref.name === 'start';
    });

    this.navigationService.getNavigationEvent().subscribe((event: NavigationEvent) => {
      switch(event.action) {
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_START:
          this.resetHeader(event.data);
          this.cartService.clearCart();
          this.resetFeature();
          break;
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_NEXT:
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_END:
          this.resetHeader(event.data);
          break;
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_PREV:
          this.resetHeader(event.data);
          break;
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_NEXT_FEATURE:
          this.nextFeature();
          break;
        case NAVIGATION_SERVICE_CONSTANTS.ACTION_PREV_FEATURE:
          this.prevFeature();
          break;
      }
    });

    this.calculatorApi.getSelectedProject().subscribe((project: Project) => {
      if(!this.project) {
        this.project = project;
        this.initCurrency();
      } else {
        this.project = project;
      }
    });

    if (this.tab.data.length < 2) {

      this.showNavigationMenu = false;
    }

  }

  ngOnInit(): void {}

  selectedFeature(feature: Feature): void {
    this.navigationService.updateSelectedFeature(feature);
  }

  checkout(): void {
    this.navigationService.slideActiveTabForward({
      showNavigationMenu: false,
      showCheckout: false,
      showCurrencySelector: true,
      showDefaultHeader: true,
      isStart: false
    });
  }

  private initCurrency() {
    this.appConfiguration.clientConfiguration.getSelectedCurrency().subscribe((currency: any) => {
        try {
          if (this.project) {

            this.productService.getFxRate(
              this.project.baseCurrency.iso2,
              currency.iso2
            ).subscribe((exchangeRate: ExchangeRate) => {
              this.cartService.setCostProperties(this.project.costPerTonne, currency, exchangeRate.rate);
            });
          }
        } catch (e) { }
    });
  }

  private createFeatureComponents() {
    this.navAsSelect = this.appConfiguration.clientConfiguration.navAsSelect;

    const components =
      this.appConfiguration.clientConfiguration.clientSettings.componentHierarchy;

    const headerComponents: Array<{ ref: any, component: Type<any> }> =
      new Array<{ ref: any, component: Type<any> }>();

    if (components.headerComponents) {
      components.headerComponents.forEach(headerComponent => {
        headerComponents.push({
          ref: headerComponent.templateName,
          component: this.featureComponentRegistryService.getComponent(headerComponent.componentName)
        });
      });
    }

    this.featureComponents =
      new Array<{ ref: any, component: Type<any> }>();

    if (components.featureComponents) {
      components.featureComponents.forEach(featureComponent => {
        this.featureComponents.push({
          ref: featureComponent,
          component: this.featureComponentRegistryService.getComponent(featureComponent.component.componentName)
        });
      });
    }

    const contentComponents: Array<{ ref: any, component: Type<any> }> =
      new Array<{ ref: any, component: Type<any> }>();

    if (components.global) {
      components.global.forEach(globalComponent => {
        const component = globalComponent.component;

        if (component.templateName) {
          contentComponents.push({
            ref: component.templateName,
            component: this.featureComponentRegistryService.getComponent(component.componentName)
          });
        } else if (component.defaultFeatureIndex >= 0) {
          contentComponents.push(this.featureComponents[component.defaultFeatureIndex]);
        }
      });
    }

    const footerComponents: Array<{ ref: any, component: Type<any> }> =
      new Array<{ ref: any, component: Type<any> }>();

    if (components.footerComponents) {
      components.footerComponents.forEach(footerComponent => {
        footerComponents.push({
          ref: footerComponent.templateName,
          component: this.featureComponentRegistryService.getComponent(footerComponent.componentName)
        });
      });
    }

    this.tab = new Tab(
      headerComponents,
      contentComponents,
      footerComponents,
      this.featureComponents
    );

    this.navigationService.setFeaturesCount(this.featureComponents.length);
  }

  private resetHeader(data) {
    this.showNavigationMenu = data.showNavigationMenu;
    this.showCheckout = data.showCheckout && !this.isCartEmpty;
    this.showCurrencySelector = data.showCurrencySelector;
    this.showDefaultHeader = data.showDefaultHeader;
    this.isStart = data.isStart;
  }

  private nextFeature() {
    if (this.container.selectedIndex === (this.container.featureComponents.length - 1)) {
      this.navigationService.slideActiveTabForward({
        showNavigationMenu: false,
        showCheckout: false,
        showCurrencySelector: true,
        showDefaultHeader: false,
        isStart: false
      });

    } else {
      this.container.selectFeature(++this.container.selectedIndex);
    }
  }

  private prevFeature() {
    if (this.container.selectedIndex === 0) {
      this.container.selectFeature(this.container.featureComponents.length - 1);
    } else {
      this.container.selectFeature(--this.container.selectedIndex);
    }
  }

  private resetFeature() {
    this.container.selectFeature(0);
  }
}
