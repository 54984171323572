import { Type } from '@angular/core';

export class Tab {

  constructor(
    public headerComponents: Array<{ ref: any, component: Type<any> }>,
    public contentComponents: Array<{ ref: any, component: Type<any> }>,
    public footerComponents: Array<{ ref: any, component: Type<any> }>,
    public data?: any,
    public name?: string) {}

}
