<div class="calculator-intro-panel">
    <div class="calculator-form-title">{{ 'intro.description' | translate }}</div>
    <div class="calculator-intro row">
        <div class="col-sm-4">
            <div class="instruction">
                <div>
                    <div class="arrow"></div>
                    <ol start="1">
                        <li class="instruction-text">{{ 'intro.instruction-text-1' | translate }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="instruction">
                <div>
                    <div class="checklist"></div>
                    <ol start="2">
                        <li class="instruction-text">{{ 'intro.instruction-text-2' | translate }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="instruction">
                <div>
                    <div class="basket"></div>
                    <ol start="3">
                        <li class="instruction-text">{{ 'intro.instruction-text-3' | translate }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!showFooterCaption" class="calculator-intro-support-message">
        {{ 'intro.extra-text' | translate }}
    </div>
    <div class="feature-nav">
        <app-feature-scroller
          [showBack]="false"
          (forward)="nextFeature()"
          (back)="previousFeature()"
        ></app-feature-scroller>
      </div>
</div>
