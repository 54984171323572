import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareOffsetsComponent } from './share-offsets.component';

@NgModule({
  declarations: [
    ShareOffsetsComponent
  ],
  imports: [
    CommonModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  exports: [
    ShareOffsetsComponent
  ],
  providers: []
})
export class SharedOffsetsModule {}


