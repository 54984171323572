import { NgModule } from '@angular/core';
import { NgxBootstrapIconsModule, cartFill } from 'ngx-bootstrap-icons';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { CalculatorHeaderComponent } from './calculator-header.component';

@NgModule({
  declarations: [
    CalculatorHeaderComponent
  ],
  imports: [
    CommonModule,
    NgxBootstrapIconsModule.pick({ cartFill }),
    TranslateModule
  ],
  exports: [
    CalculatorHeaderComponent
  ]
})
export class CalculatorHeaderModule { }
