<div class="calculator-form row">
  <div class="col-md-7">
    <form id="energyForm" [formGroup]="energyForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">ENERGY</div>
        <p>Calculate and offset your energy use emissions here:</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Energy Type</label>
        <select class="form-control" formControlName="type" (change)="changeEnergyType($event.currentTarget.selectedIndex)">
          <option
            *ngFor="let energyType of energyTypes"
            value="{{ energyType.apiKey }}"
          >
            {{ energyType.label }}
          </option>
        </select>
      </div>
      <div *ngIf="energyTypeHasCountryData" class="form-group inline">
        <label class="col-form-label">Country</label>
        <select class="form-control" formControlName="source">
          <option
            *ngFor="let country of countries"
            value="{{ country }}"
          >
            {{ country }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Amount</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="consumption"
        />
        <select class="form-control distance-unit" formControlName="unit">
          <option
            *ngFor="let energyUnit of energyUnits"
            value="{{ energyUnit.apiKey }}"
          >
            {{ energyUnit.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <div class="form-check form-check-inline" style="display: inline-flex!important; gap: 5px;">
          <input class="renewable-checkbox" type="checkbox" value="" formControlName="isRenewable">
          <label class="renewable-caption"
            style="width: auto; line-height: unset;display: contents;">
            Was this certified 100% renewable energy?
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="energyForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
</div>
</div>

<div *ngIf="errors.length" class="container-fluid p-0">
  <div *ngFor="let error of errors" class="error-message">
    <span class="fs-14 text-danger italic">{{ error }}</span>
  </div>
</div>
