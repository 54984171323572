import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { OffsetProjectsComponent } from '../offset-projects/offset-projects.component';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';
import { ProjectCardModule } from '../../../shared/components/project-card/project-card.module';
import { ProductService } from '../../../shared/services/product/product.service';
import { ProjectService } from '../../../shared/services/projects/projects.service';

@NgModule({
  declarations: [
    OffsetProjectsComponent
  ],
  imports: [
    CommonModule,
    PostPurchaseNavigationModule,
    ProjectCardModule,
    TranslateModule
  ],
  exports: [
    OffsetProjectsComponent
  ],
  providers: [
    ProjectService,
    ProductService
  ]
})
export class OffsetProjectsModule {}
