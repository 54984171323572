import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarbonAnalyticsFooterComponent } from './carbon-analytics-footer.component';

@NgModule({
  declarations: [
    CarbonAnalyticsFooterComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CarbonAnalyticsFooterComponent
  ]
})
export class CarbonAnalyticsFooterModule { }
