import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBootstrapIconsModule, trash } from 'ngx-bootstrap-icons';
import { TranslateModule } from '@ngx-translate/core';

import { CartComponent } from './cart.component';
import { CurrencySymbolModule } from '../../../shared/pipes/currency-symbol/currency-symbol.module';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CartComponent,
  ],
  exports: [
    CartComponent
  ],
  imports: [
    CommonModule,
    NgxBootstrapIconsModule.pick({ trash }),
    PostPurchaseNavigationModule,
    CurrencySymbolModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CartModule {}