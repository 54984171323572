import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencySymbolModule } from '../../../shared/pipes/currency-symbol/currency-symbol.module';
import { FlexStripeCardModule } from '../../../shared/components/flex-stripe-card/flex-stripe-card.module';
import { PaymentService } from '../../../shared/services/payments/payment.service';
import { PayOffsetsComponent } from './pay-offsets.component';
import { PostPurchaseNavigationModule } from '../post-purchase-navigation/post-purchase-navigation.module';

@NgModule({
  declarations: [
    PayOffsetsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PostPurchaseNavigationModule,
    FlexStripeCardModule,
    CurrencySymbolModule,
    TranslateModule
  ],
  providers: [
    PaymentService
  ],
  exports: [
    PayOffsetsComponent
  ]
})
export class PayOffsetsModule { }
