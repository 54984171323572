<div class="climatecare-inner container">
  <app-calculator-header
    (checkout)="checkout()"
    [showDefaultHeader]="showDefaultHeader"
    [showCheckout]="showCheckout"
    [showCurrencySelector]="showCurrencySelector"
    [totalTonnes]="totalTonnes"
    [currencies]="currencies"
    [logoImg]="logoImg"
  >
  </app-calculator-header>
  <app-tabbed-container
    [tab]="tab"
    [navAsSelect]="navAsSelect"
    [showNavigationMenu]="showNavigationMenu"
    [selectedIndex]="defaultTabIndex"
    (selectedFeature)="selectedFeature($event)">
  </app-tabbed-container>
</div>
