import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OffsetInputModule } from '../../../shared/components/offset-input/offset-input.module';

import { ClimateCareAccommodationsComponent } from './accommodations.component';
import { GenericTypeaheadModule } from '../../../shared/components/generic-typeahead/generic-typeahead.module';
import { PurchaseEmissionsModule } from '../purchase-emissions/purchase-emissions.module';

@NgModule({
  declarations: [
    ClimateCareAccommodationsComponent
  ],
  imports: [
    CommonModule,
    OffsetInputModule,
    FormsModule,
    ReactiveFormsModule,
    PurchaseEmissionsModule,
    GenericTypeaheadModule
  ],
  exports: [
    ClimateCareAccommodationsComponent
  ]
})
export class ClimateCareAccommodationsModule { }
