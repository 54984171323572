import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabCarouselComponent } from './tab-carousel.component';
import { TabContentHostModule } from '../tab-content/tab-content-host.module';
import { NavigationService } from '../../../../shared/services/navigation/navigation.service';

@NgModule({
  declarations: [
    TabCarouselComponent
  ],
  imports: [
    CommonModule,
    TabContentHostModule
  ],
  providers: [
    NavigationService
  ],
  exports: [
    TabCarouselComponent
  ],
})
export class TabCarouselModule { }
