import { Component, Injector, OnInit } from '@angular/core';

import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-carbon-analytics-footer',
  templateUrl: './carbon-analytics-footer.component.html',
  styleUrls: ['./carbon-analytics-footer.component.css']
})
export class CarbonAnalyticsFooterComponent implements OnInit, FeatureComponent {

  data: any;

  constructor(private injector: Injector) { }

  ngOnInit(): void {
  }

}
