import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Cart, CartCurrency } from './cart.interface';
import { CartItem } from './cart-item.interface';

@Injectable()
export class CartService {

  vatCountries: Array<string> = ['United Kingdom'];
  vatAmountPercentage = 20; //percentage

  private cart: BehaviorSubject<Cart>;

  constructor(private appConfiguration: AppConfiguration) {
    this.cart = new BehaviorSubject<Cart>({
      currency: {},
      costPerTonne: 0,
      totalTonnes: 0,
      totalCost: 0,
      items: [],
      fxRate: 1,
      vatAmount: 0
    });
  }

  getCart(): BehaviorSubject<Cart> {
    return this.cart;
  }

  isEmpty(): boolean {
    return this.cart.getValue().items.length === 0;
  }

  clearCart(): void {
    const cart = this.cart.getValue();

    cart.costPerTonne = 0;
    cart.totalTonnes = 0;
    cart.totalCost = 0;

    let vat = {};
    cart.items.forEach((currentValue, index) => {
      if(currentValue.vat) {
        vat = {country : currentValue.vat.country, 
          address : currentValue.vat.address, 
          amount: currentValue.vat.amount};
      }
    });

    cart.items = [];
    cart.items.push({vat});
    this.cart.next(cart);
  }

  setCostProperties(costPerTonne: number, currency: CartCurrency, rate: number = 1) {
    const cart = this.cart.getValue();

    cart.currency = currency;
    cart.fxRate = rate;
    cart.costPerTonne = Math.round(costPerTonne * rate * 100) / 100;

    this.refresh(cart);

    this.cart.next(cart);
  }

  updateCart(cart: Cart) {
    this.cart.next(cart);
  }

  addItem(item: CartItem) {
    const cart = this.cart.getValue();
  
    if(item.vat) {
      cart.items.forEach((currentValue, index) => {
        if(currentValue.vat) {
            cart.items.splice(index, 1);
        }
      });
    }

    if (this.canAddVoucherItem(item, cart)) {
      cart.items.push(item);
      this.refresh(cart);
      this.cart.next(cart);
    }
  }

  removeItem(index: number) {
    const cart = this.cart.getValue();

    cart.items.splice(index, 1);
    this.refresh(cart);

    this.cart.next(cart);
  }

  private refresh(cart: Cart, ) {
    cart.hasDiscount = false;
    cart.totalCost = 0;
    cart.totalTonnes = 0;
    cart.vatAmount = 0;

    cart.items.forEach((item: CartItem) => {
      if (item.emissionsItem) {
        cart.totalTonnes += item.emissions;
        item.cost = Math.round((item.emissions * cart.costPerTonne) * 100) / 100;
        item.emissions = item.emissions;
        cart.totalCost += item.cost;
      }
    });
    cart.totalTonnes = cart.totalTonnes;

    this.resolveVoucherCodes(cart);
    this.resolveVat(cart);
    this.resolveHasMinimum(cart);
  }

  private resolveVoucherCodes(cart: Cart) {
    cart.items.forEach((item: CartItem) => {
      if (item.voucherCode && item.voucherCode.context === 'percentage') {
        cart.totalCost += (item.voucherCode.amount * cart.totalCost * 0.01);
        cart.totalCost = cart.totalCost < 0 ? 0 : cart.totalCost;
        cart.hasDiscount = true;
      }
    });
  }

  private resolveVat(cart: Cart) {

    let amount = this.vatAmountPercentage;
    let country = this.vatCountries[0];

    if(!this.hasVat(cart)) {
      const vat = {country : country, address: '', amount: amount };
      cart.items.push({vat});
    } else {
       cart.items.forEach((currentValue, index) => {
          if(currentValue.vat) {
            amount = currentValue.vat.amount;
            country = currentValue.vat.country;
          }
        });
    }
    cart.vatAmount = (amount / 100) *  cart.totalCost;
    cart.totalCost = cart.totalCost + cart.vatAmount;
  }

  updateVatAddress(value:string){
    const cart = this.cart.getValue();

    let vat = {};
    cart.items.forEach((currentValue, index) => {
      if(currentValue.vat) {
        currentValue.vat.address = value;
      }
    });

    console.log(cart.items);
  }

  private hasVat(cart: Cart){
    return !!cart.items.filter((item: CartItem) => { return item.vat; }).length;
  }

  private canAddVoucherItem(addedItem: CartItem, cart: Cart): boolean {
    return !!!cart.items.
      filter((item: CartItem) => {
        return item.voucherCode && addedItem.voucherCode && item.voucherCode.code === addedItem.voucherCode.code;
      }).length;
  }

  private resolveHasMinimum(cart: Cart) {
    const expectedMinimum =
      this.appConfiguration.clientConfiguration.clientSettings.minimumBaseCurrencyAmount * cart.fxRate;
    cart.hasMinimum = !!(cart.totalCost > expectedMinimum);
  }

  getExpectedMinimum(cart: Cart) {
   return this.appConfiguration.clientConfiguration.clientSettings.minimumBaseCurrencyAmount * cart.fxRate;
  }
}
