export const NAVIGATION_SERVICE_CONSTANTS = {
  TARGET_NAVIGATION_MENU: 'navigation-menu',
  TARGET_SELECT_PROJECT: 'select-project',
  TARGET_CAROUSEL_CONTENT: 'app-tab-carousel-content',
  TARGET_CURRENCY_SELECTOR: 'currency-selector',
  ACTION_NEXT: 'next',
  ACTION_PREV: 'prev',
  ACTION_START: 'start',
  ACTION_END: 'end',
  ACTION_NEXT_FEATURE: 'nextFeature',
  ACTION_PREV_FEATURE: 'prevFeature',
};
