<div *ngIf="!appReady" class="loading">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="appReady && appAuthorized" class="climatecare-outer">
    <router-outlet></router-outlet>    
</div>
<div *ngIf="appReady && !appAuthorized" class="loading">
    <div>UNAUTHORIZED</div>
</div>
