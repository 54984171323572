import { AppConfiguration } from "../../../bootstrap/climatecare/app.configuration";

export interface EmissionsOption {
  label?: string;
  apiKey?: string;
  units?: Array<Array<EmissionsOption>>,
  hasCountryData?: boolean,
  correletadField?: string,
}

export class EmissionsOptionsCatalog {

  private optionsMap: Map<string, Array<EmissionsOption>>;

  constructor(private appConfiguration: AppConfiguration) {
    this.initOptionsMap();
  }

  getOptions(optionsType: string): Array<EmissionsOption> {
    if (!this.optionsMap.has(optionsType)) {
      return [];
    }
    return this.optionsMap.get(optionsType);
  }

  private initOptionsMap() {
    this.optionsMap = new Map<string, Array<EmissionsOption>>();

    const flightClasses = [];

    if (this.isClient('chinaair')) {
      flightClasses.push({ label: 'flights.flight-class-business', apiKey: 'business' });
      flightClasses.push({ label: 'flights.flight-class-premium-economy', apiKey: 'premium economy' });
      flightClasses.push({ label: 'flights.flight-class-economy', apiKey: 'economy' });
      flightClasses.push({ label: 'flights.flight-class-cargo', apiKey: 'cargo' });
    } else if(this.isClient('evaair')){
      flightClasses.push({ label: 'flights.flight-class-economy-premium-economy', apiKey: 'economy' });
      flightClasses.push({ label: 'flights.flight-class-business', apiKey: 'business' });
    }else {
      flightClasses.push({ label: 'flights.flight-class-economy-premium-economy', apiKey: 'economy' });
      flightClasses.push({ label: 'flights.flight-class-business', apiKey: 'business' });
      flightClasses.push({ label: 'flights.flight-class-first', apiKey: 'first' });
    }

    this.optionsMap.set('flightClasses', flightClasses);

    this.optionsMap.set('flightTypes', [
        { label: 'flights.flight-type-return', apiKey: 'return' },
        { label: 'flights.flight-type-one-way', apiKey: 'one way' }
      ]
    );

    this.optionsMap.set(
      'carFuelTypes',
      [
        { label: 'LPG', apiKey: 'lpg' },
        { label: 'Petrol', apiKey: 'petrol' },
        { label: 'Gasoline', apiKey: 'gasoline' },
        { label: 'Diesel', apiKey: 'diesel' },
        { label: 'Electric', apiKey: 'electric', hasCountryData: true },
        { label: 'Hybrid', apiKey: 'hybrid' }
      ]
    );

    this.optionsMap.set(
      'transportationTypes', [
        {
          label: 'Car',
          apiKey: 'car',
          units: [
            this.optionsMap.get('carFuelTypes')
          ]
        },
        { label: 'Motorbike', apiKey: 'motorbike' },
        { label: 'Taxi', apiKey: 'taxi' },
        { label: 'Train', apiKey: 'train' },
        { label: 'Bus', apiKey: 'bus' },
        { label: 'Metro', apiKey: 'metro' },
        { label: 'Tube', apiKey: 'tube' }
      ]
    );

    this.optionsMap.set('distanceUnits', [
      { label: 'Miles', apiKey: 'miles' },
      { label: 'Km', apiKey: 'km' }
    ]);

    this.optionsMap.set('electricityUnitTypes', [
      { label: 'kWh', apiKey: 'kwh' },
      { label: 'mWh', apiKey: 'mwh' }
    ]);

    this.optionsMap.set('naturalGasUnitTypes', [
      { label: 'Liters', apiKey: 'l' },
      { label: 'UK Gallon', apiKey: 'uk gallon' },
      { label: 'US Gallon', apiKey: 'us gallon' },
      { label: 'Btu', apiKey: 'btu' },
      { label: 'Tonnes', apiKey: 't' },
      { label: 'Cubic meters', apiKey: 'm3' },
      { label: 'Cubic yards', apiKey: 'y3' }
    ]);

    this.optionsMap.set('lpgUnitTypes', [
      { label: 'kWh', apiKey: 'kwh' },
      { label: 'Btu', apiKey: 'btu' },
      { label: 'Tonnes', apiKey: 't' },
      { label: 'Cubic meters', apiKey: 'm3' },
      { label: 'Cubic yards', apiKey: 'y3' }
    ]);

    this.optionsMap.set('cngUnitTypes', [
      { label: 'kWh', apiKey: 'kwh' },
      { label: 'Btu', apiKey: 'btu' },
      { label: 'Tonnes', apiKey: 't' },
      { label: 'Cubic meters', apiKey: 'm3' },
      { label: 'Cubic yards', apiKey: 'y3' }
    ]);

    this.optionsMap.set('heatingOilUnitTypes', [
      { label: 'Liters', apiKey: 'l' },
      { label: 'UK Gallon', apiKey: 'uk gallon' },
      { label: 'US Gallon', apiKey: 'us gallon' }
    ]);

    this.optionsMap.set('importedHeatUnitTypes', [
      { label: 'kWh', apiKey: 'kwh' }
    ]);

    this.optionsMap.set('petrolUnitTypes', [
      { label: 'Liters', apiKey: 'l' },
      { label: 'UK Gallon', apiKey: 'uk gallon' },
      { label: 'US Gallon', apiKey: 'us gallon' }
    ]);

    this.optionsMap.set('dieselUnitTypes', [
      { label: 'Liters', apiKey: 'l' },
      { label: 'UK Gallon', apiKey: 'uk gallon' },
      { label: 'US Gallon', apiKey: 'us gallon' }
    ]);

    this.optionsMap.set('energyTypes', [
      {
        label: 'Electricity',
        apiKey: 'electricity',
        units: [
          this.optionsMap.get('electricityUnitTypes')
        ],
        hasCountryData: true
      },
      {
        label: 'Natural Gas',
        apiKey: 'naturalGas',
        units: [
          this.optionsMap.get('naturalGasUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'LPG',
        apiKey: 'lpg',
        units: [
          this.optionsMap.get('lpgUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'CNG',
        apiKey: 'cng',
        units: [
          this.optionsMap.get('cngUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Heating Oil',
        apiKey: 'heatingOil',
        units: [
          this.optionsMap.get('heatingOilUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Imported Heat',
        apiKey: 'importedHeat',
        units: [
          this.optionsMap.get('importedHeatUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Imported Steam',
        apiKey: 'importedSteam',
        units: [
          this.optionsMap.get('importedHeatUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Imported Cooling',
        apiKey: 'importedCooling',
        units: [
          this.optionsMap.get('importedHeatUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Petrol',
        apiKey: 'petrol',
        units: [
          this.optionsMap.get('petrolUnitTypes')
        ],
        hasCountryData: false
      },
      {
        label: 'Diesel',
        apiKey: 'diesel',
        units: [
          this.optionsMap.get('dieselUnitTypes')
        ],
        hasCountryData: false
      }
    ]);

    this.optionsMap.set('mealTypes', [
      {
        label: 'Meat',
        apiKey: 'meat'
      },
      {
        label: 'Fish',
        apiKey: 'fish'
      },
      {
        label: 'Vegetarian',
        apiKey: 'vegetarian'
      },
      {
        label: 'Vegan',
        apiKey: 'vegan'
      },
      {
        label: 'Meat Monogastric (non-ruminant)',
        apiKey: 'meat monogastric'
      },
      {
        label: 'Meat Partially Replaced by Plant-based Food',
        apiKey: 'meat partially replaced by plant-based food'
      },
      {
        label: 'Meat Partially Replaced by Dairy Products',
        apiKey: 'meat partially replaced by dairy products'
      },
      {
        label: 'Meat Partially Replaced by Mixed Food',
        apiKey: 'meat partially replaced by mixed food'
      }
    ]);

    this.optionsMap.set('accommodationsUnits', [
      {
        label: 'Luxury',
        apiKey: 'luxury'
      },
      {
        label: 'Standard',
        apiKey: 'standard'
      }
    ]);

    this.optionsMap.set('accommodationsTypes', [
      {
        label: 'Hotel',
        apiKey: 'hotel',
        units: [
          this.optionsMap.get('accommodationsUnits')
        ]
      },
      {
        label: 'Bed & Breakfast',
        apiKey: 'b&b'
      },
      {
        label: 'Self Catering',
        apiKey: 'condo'
      },
      {
        label: 'Tent',
        apiKey: 'tent'
      },
      {
        label: 'Cruise',
        apiKey: 'cruise',
        units: [
          this.optionsMap.get('accommodationsUnits')
        ]
      }
    ]);

    this.optionsMap.set('freightWeightUnitTypes', [
        { label: 'Kilograms', apiKey: 'kg' },
        { label: 'Lbs', apiKey: 'lbs' },
        { label: 'Tonnes', apiKey: 'tonnes' }
      ]
    );

    this.optionsMap.set('freightDistanceUnits', [
      { label: 'Miles', apiKey: 'miles' },
      { label: 'Km', apiKey: 'km' }
    ]);

    this.optionsMap.set('freightAirDistanceUnits', [
      { label: 'Miles', apiKey: 'miles' },
      { label: 'Km', apiKey: 'km' },
      { label: 'Hours', apiKey: 'hours' }
    ]);

    this.optionsMap.set('freightClassTypes', [
      {
        label: 'Air',
        apiKey: 'air',
        correletadField: 'air',
        units: [
          this.optionsMap.get('freightAirDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Bicycle',
        apiKey: 'bike',
        correletadField: 'land',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Van',
        apiKey: 'van',
        correletadField: 'land',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Rigid',
        apiKey: 'rigid',
        correletadField: 'land',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Articulated',
        apiKey: 'articulated',
        correletadField: 'land',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Crude Tanker',
        apiKey: 'crude tanker',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Product Tanker',
        apiKey: 'product tanker',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'LNG Tanker',
        apiKey: 'LNG tanker',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'LPG Tanker',
        apiKey: 'LPG tanker',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Bulk Carrier',
        apiKey: 'bulk carrier',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'General Cargo',
        apiKey: 'general cargo',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Container Ship',
        apiKey: 'container ship',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Vehicle Transport',
        apiKey: 'vehicle transport',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'RoRo Ferry',
        apiKey: 'RoRo-Ferry',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      },
      {
        label: 'Large RoPax Ferry',
        apiKey: 'large RoPax ferry',
        correletadField: 'water',
        units: [
          this.optionsMap.get('freightDistanceUnits'),
          this.optionsMap.get('freightWeightUnitTypes')
        ]
      }
    ]);

    this.optionsMap.set('wasteEolTypes', [
      { label: 'Landfil', apiKey: 'landfil' },
      { label: 'Recycled', apiKey: 'recycled' },
      { label: 'Composted', apiKey: 'composted' },
      { label: 'Incinerated', apiKey: 'incinerated' } ,
      { label: 'Anaerobic Digestion', apiKey: 'ad' }
    ]);

    this.optionsMap.set('wasteUnitTypes', [
      { label: 'Kilograms', apiKey: 'kg' },
      { label: 'Tonnes', apiKey: 't' },
      { label: 'Bags', apiKey: 'bags' },
      { label: 'Lbs', apiKey: 'lbs' }
    ]);

    this.optionsMap.set('wasteTypes', [
      {
        label: 'Food Waste',
        apiKey: 'food waste',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Composted', apiKey: 'composted' },
            { label: 'Incinerated', apiKey: 'incinerated' },
            { label: 'Anaerobic Digestion', apiKey: 'ad' }
          ]
        ]
      },
      {
        label: 'Wood Paper and Card',
        apiKey: 'wood paper and card',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Recycled', apiKey: 'recycled' },
            { label: 'Composted', apiKey: 'composted' },
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      },
      {
        label: 'Electrical Equipment',
        apiKey: 'electrical equipment',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Recycled', apiKey: 'recycled' },
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      },
      {
        label: 'Plastics',
        apiKey: 'plastics',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Recycled', apiKey: 'recycled' },
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      },
      {
        label: 'Metals',
        apiKey: 'metals',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Recycled', apiKey: 'recycled' },
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      },
      {
        label: 'Construction',
        apiKey: 'construction',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' },
            { label: 'Recycled', apiKey: 'recycled' },
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      },
      {
        label: 'Landfill Waste',
        apiKey: 'landfil waste',
        units: [
          [
            { label: 'Landfill', apiKey: 'landfil' }
          ]
        ]
      },
      {
        label: 'Recycled Waste',
        apiKey: 'recycled waste',
        units: [
          [
            { label: 'Recycled', apiKey: 'recycled' }
          ]
        ]
      },
      {
        label: 'Anaerobic Digestion',
        apiKey: 'anaerobic digestion',
        units: [
          [
            { label: 'Anaerobic Digestion', apiKey: 'ad' }
          ]
        ]
      },
      {
        label: 'Incineration',
        apiKey: 'incineration',
        units: [
          [
            { label: 'Incinerated', apiKey: 'incinerated' }
          ]
        ]
      }
    ]);

    this.optionsMap.set('eventTransporationTypes', [
      { label: 'Car', apiKey: 'car' },
      { label: 'Flight', apiKey: 'flight' },
      { label: 'Train', apiKey: 'rail' },
      { label: 'Bus', apiKey: 'bus' }
    ]);

    this.optionsMap.set('eventFlightRanges', [
      {
        label: 'Short',
        apiKey: 'short'
      },
      {
        label: 'Medium',
        apiKey: 'medium'
      },
      {
        label: 'Long',
        apiKey: 'long'
      }
    ]);

    this.optionsMap.set('eventFlightClasses', [
      {
        label: 'Economy',
        apiKey: 'economy'
      },
      {
        label: 'Business',
        apiKey: 'business'
      },
      {
        label: 'First',
        apiKey: 'first'
      }
    ]);

    this.optionsMap.set('venueUnits', [
      {
        label: 'ft2',
        apiKey: 'sq.ft'
      },
      {
        label: 'm2',
        apiKey: 'sq.m'
      }
    ]);

    this.optionsMap.set('venueTypes', [
      {
        label: 'Education',
        apiKey: 'education'
      },
      {
        label: 'Food sales',
        apiKey: 'food sales'
      },
      {
        label: 'Food service',
        apiKey: 'food service'
      },
      {
        label: 'Health care',
        apiKey: 'health care'
      },
      {
        label: 'Lodging',
        apiKey: 'lodging'
      },
      {
        label: 'Mercantile',
        apiKey: 'mercantile'
      },
      {
        label: 'Retail',
        apiKey: 'retail'
      },
      {
        label: 'Enclosed and strip malls',
        apiKey: 'enclosed and strip malls'
      },
      {
        label: 'Office',
        apiKey: 'office'
      },
      {
        label: 'Public assembly',
        apiKey: 'public assembly'
      },
      {
        label: 'Public order and safety',
        apiKey: 'public order and safety'
      },
      {
        label: 'Religious worship',
        apiKey: 'religious worship'
      },
      {
        label: 'Service',
        apiKey: 'service'
      },
      {
        label: 'Warehouse and storage',
        apiKey: 'warehouse and storage'
      },
      {
        label: 'Vacant',
        apiKey: 'vacant'
      }
    ]);
  }

  private isClient(client: string) {
    let clientFound = false;

    const calcClient =
      this.appConfiguration.clientConfiguration.clientSettings.client;

    if (calcClient === client) {
      clientFound = true;
    }

    return clientFound;
  }
}
