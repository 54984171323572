<div class="purchase-confirmation">
    <p class="title-caption">{{ 'confirmation.header' | translate }}</p>
    <p class="message-caption">{{ 'confirmation.message-1' | translate }}</p>
    <p class="sub-message-caption">{{ 'confirmation.message-2' | translate }}</p>
    <p class="sub-message-caption">{{ 'confirmation.message-3' | translate }}</p>
</div>
<div class="shared">
    <p class="message-caption">{{ 'confirmation.message-4' | translate }}</p>
    <p class="share-message-caption">{{ shareMessage }}</p>
    <app-share-offsets [siteUrl]="siteUrl" [tonnes]="tonnes" [shareMessage]="shareMessage"></app-share-offsets>
</div>
<div>
    <app-post-purchase-navigation 
        [target]="data.id"
        [hasNext]="false"
        (back)="back()">
    </app-post-purchase-navigation>
</div>