<div class="header-wrapper">
  <select *ngIf="navAsSelect" 
    [ngStyle]="{ 'background-color': selectedColor }"
    [ngClass]="selectedTextColorClass"
    [(ngModel)]="activeContent"
    (change)="changeFeature($event.target.selectedIndex)" 
    class="custom-select" >
    <option *ngFor="let featureComponent of featureComponents; let i = index;" 
      [value]="i" 
      [ngStyle]="{ 'background-color': featureComponent.ref.theme.fillColor }"
      [ngClass]="featureComponent.ref.theme.textClass">
      {{ featureComponent.ref.seqn }} | {{ featureComponent.ref.title }}
    </option>
  </select>
</div>

<ul *ngIf="!navAsSelect" class="nav nav-tabs" id="climatecare-tab-list" role="tablist">
  <li *ngFor="let featureComponent of featureComponents; let i = index;" class="nav-item">
    <a class="nav-item nav-link active" id="{{ featureComponent.ref.name | lowercase }}-tab" data-toggle="tab" 
      href="#nav-{{ featureComponent.ref.name | lowercase }}" role="tab" attr.aria-controls="nav-{{ featureComponent.ref.name | lowercase }}" aria-selected="true"
      [ngClass]="selectedTextColorClass"
      (click)="changeFeature(i)">
      {{ featureComponent.ref.name | titlecase }}
    </a>
  </li>
</ul>