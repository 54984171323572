import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { CartService } from '../../../shared/services/cart/cart.service';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit, FeatureComponent {

  data: any;

  siteUrl: string;

  shareMessage: string;

  tonnes: string;

  constructor(private injector: Injector) {
    const translateService = this.injector.get(TranslateService);
    const appConfiguration = this.injector.get(AppConfiguration);
   
    this.injector.get(CartService).getCart().subscribe((cart: Cart) => {
      this.tonnes = cart.totalTonnes.toFixed(2);
    });
  }

  ngOnInit(): void {
    const translateService = this.injector.get(TranslateService);
    const appConfiguration = this.injector.get(AppConfiguration);
    this.siteUrl = appConfiguration.clientConfiguration.clientSettings.siteUrl;
    translateService.get('confirmation.share-message').subscribe((res: string) => {
      this.shareMessage = res + this.siteUrl ;
    });
  }

  back(): void {
    const navigationService = this.injector.get(NavigationService);
    navigationService.slideActiveTabStart({
      showNavigationMenu: true,
      showCheckout: false,
      showCurrencySelector: true,
      showDefaultHeader: true,
      isStart: true
    });
  }

}
