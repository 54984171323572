<div class="calculator-form row">
  <div class="col-md-7">
    <form id="startForm" [formGroup]="startForm">
      <div class="form-group">
        <div class="calculator-form-title">{{ 'start.quick-offset-caption' | translate }}</div>
      </div>
      <div class="form-group">
        <div class="calculator-form-content-small">{{ 'start.quick-offset-message' | translate }}</div>
      </div>
      <div class="form-group">
        <label>
          <span class="calculator-form-content">
            {{ 'start.quick-offset-message-1-prefix' | translate }} CO<sub>2</sub>e {{ 'start.quick-offset-message-1-suffix' | translate }}
          </span>
        </label>
        <input
          type="number"
          name="tonnes"
          class="form-control"
          placeholder="{{'start.quick-offset-emission-placeholder-caption' | translate }}"
          formControlName="emissions"
          [attr.disabled]="emissionsRequestActivated ? '' : null"
          [(ngModel)]="tonnes"
          (change)="updateTonnes($event.currentTarget.value)"
        />
      </div>
      <div class="form-group">
        <label>
          <span class="calculator-form-content">{{ 'start.quick-offset-message-2' | translate }}</span>
        </label>
        <input
          type="number"
          name="tonnes"
          class="form-control"
          formControlName="emissionsPerUnitCost"
          placeholder="{{'start.quick-offset-emission-per-unit-cost-placeholder-caption' | translate }}"
          [attr.disabled]="emissionsRequestActivated ? '' : null"
          [(ngModel)]="cost"
          (change)="updateCost($event.currentTarget.value)"
        />
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestValid]="validOffset"
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"></app-purchase-emissions>
  </div>
</div>
