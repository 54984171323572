import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { FeatureComponent } from '../../../features/calculators/feature-component-registry/feature-component.interface';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { Tab } from './tab.model';

@Component({
  selector: 'app-tabbed-container',
  templateUrl: './tabbed-container.component.html',
  styleUrls: ['./tabbed-container.component.css']
})
export class TabbedContainerComponent implements OnInit {
  @ViewChild(TabHeaderComponent) header!: TabHeaderComponent;

  @Input()
  tab: Tab;

  @Input()
  navAsSelect ?= false;

  @Input()
  showNavigationMenu ?= true;

  @Input()
  selectedIndex ?= 0;

  @Output()
  selectedFeature: EventEmitter<FeatureComponent>;

  footerComponents = [];

  featureComponents = [];

  contentComponents = [];

  constructor() {
    this.selectedFeature = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.featureComponents = this.tab.data;
    this.contentComponents = this.tab.contentComponents;
    this.footerComponents = this.tab.footerComponents;
  }

  selectFeature(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
    this.header.activeContent = selectedIndex;
    this.header.updateSelectedColor();
    this.selectedFeature.emit(this.featureComponents[this.selectedIndex]);
  }
}
