import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabbedContainerComponent } from '../tabbed-container/tabbed-container.component';
import { TabHeaderModule } from './tab-header/tab-header.module';
import { TabContentModule } from './tab-content/tab-content.module';
import { TabFooterModule } from './tab-footer/tab-footer.module';

@NgModule({
  declarations: [
    TabbedContainerComponent
  ],
  imports: [
    CommonModule,
    TabHeaderModule,
    TabContentModule,
    TabFooterModule
  ],
  exports: [
    TabbedContainerComponent
  ],
  providers: []
})
export class TabbedContainerModule { }
