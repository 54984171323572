import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { Project } from '../../services/calculator-api/project.interface';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './project-card.component.css' ]
})
export class ProjectCardComponent implements OnInit {

  @Input()
  project: Project;

  @Output()
  chooseProject: EventEmitter<Project>;

  splash: SafeResourceUrl;

  contentBlocks = [];

  offsetProject = '';
  constructor(private sanitizer: DomSanitizer) {
    this.chooseProject = new EventEmitter<Project>();
  }

  ngOnInit(): void {
    this.parseSplash();
    this.parseContentBlocks();
  }

  selectProject() {
    this.chooseProject.emit(this.project);
  }

  private parseSplash(): void {
    this.splash = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + this.project.splash);
  }

  private parseContentBlocks(): void {
    if (this.project && this.project.projectDetails.title){
      this.offsetProject = this.project.projectDetails.title.toLowerCase().replace(/\s+/g, '-');
    }
  }
}
