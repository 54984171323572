import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appTabContentHost]'
})
export class TabContentHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) {}

}
