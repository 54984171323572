import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabContentComponent } from './tab-content.component';
import { TabCarouselModule } from '../tab-carousel/tab-carousel.module';
import { TabContentHeaderModule } from '../tab-content-header/tab-content-header.module';

@NgModule({
  declarations: [
    TabContentComponent
  ],
  imports: [
    CommonModule,
    TabCarouselModule,
    TabContentHeaderModule
  ],
  exports: [
    TabContentComponent
  ],
})
export class TabContentModule { }
