import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClimateCareRefrigerationComponent} from './refrigeration.component';

@NgModule({
  declarations: [
    ClimateCareRefrigerationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClimateCareRefrigerationComponent
  ],
})
export class ClimateCareRefrigerationModule { }
