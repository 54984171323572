import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';
import { PurchaseEmissionsComponent } from './purchase-emissions.component';

@NgModule({
  declarations: [
    PurchaseEmissionsComponent
  ],
  exports: [
    PurchaseEmissionsComponent
  ],
  imports: [
    CommonModule,
    FeatureScrollerModule,
    TranslateModule
  ]
})
export class PurchaseEmissionsModule { }
