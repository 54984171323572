import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabFooterComponent } from './tab-footer.component';
import { TabContentHostModule } from '../tab-content/tab-content-host.module';

@NgModule({
  declarations: [
    TabFooterComponent
  ],
  imports: [
    CommonModule,
    TabContentHostModule
  ],
  exports: [
    TabFooterComponent
  ],
})
export class TabFooterModule { }
