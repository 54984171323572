import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-climatecare-event',
  templateUrl: './event.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './event.component.css']
})
export class ClimateCareEventComponent implements OnInit, FeatureComponent {

  data: any;

  eventForm: FormGroup;
  attendeesForms: FormArray;
  mealsForms: FormArray;
  accomodationsForms: FormArray;
  venueForm: FormGroup;
  locationForm: FormGroup;

  eventTransporationTypes: Array<EmissionsOption>;
  eventFlightRanges: Array<EmissionsOption>;
  eventFlightClasses: Array<EmissionsOption>;
  mealTypes: Array<EmissionsOption>;
  accommodationTypes: Array<EmissionsOption>;
  accommodationLevels: Array<EmissionsOption>;
  venueTypes: Array<EmissionsOption>;
  venueUnits: Array<EmissionsOption>;

  tonnes = 0;

  displayedTonnes = 0;

  flightTransporationSelected = [];

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  countries: Array<string> = [];

  internalForm: any;

  private appConfiguration: AppConfiguration;

  private cartService: CartService;

  private emissionsService: EmissionsService;

  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.eventTransporationTypes = this.emissionsService.getFieldOptions('eventTransporationTypes');
    this.eventFlightRanges = this.emissionsService.getFieldOptions('eventFlightRanges');
    this.eventFlightClasses = this.emissionsService.getFieldOptions('eventFlightClasses');
    this.mealTypes = this.emissionsService.getFieldOptions('mealTypes');
    this.accommodationTypes = this.emissionsService.getFieldOptions('accommodationsTypes');
    this.accommodationLevels = this.accommodationTypes[0].units[0];
    this.venueTypes = this.emissionsService.getFieldOptions('venueTypes');
    this.venueUnits = this.emissionsService.getFieldOptions('venueUnits');

    this.appConfiguration.clientConfiguration.getCountries().
      subscribe((countries: Array<string>) => {
        this.countries = countries;
        this.buildEventsForm();
    });

    this.eventForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      this.internalForm = this.buildInternalForm();
      if (this.internalForm) {
        this.getEmissions();
      }
    });
  }

  ngOnInit(): void { }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    // this.reset();
  }

  changeTransportationType(index: number) {
    this.flightTransporationSelected[index] =
      !!(this.eventTransporationTypes[index].apiKey === 'flight');
  }

  addAttendees() {
    this.flightTransporationSelected.push(false);
    this.attendeesForms.push(this.createAttendeesForm());
  }

  removeAttendees() {
    this.flightTransporationSelected.pop();
    this.attendeesForms.removeAt((this.attendeesForms.length - 1));
  }

  addAccommodations() {
    this.accomodationsForms.push(this.createAccommodationsForm());
  }

  removeAccommodations() {
    this.accomodationsForms.removeAt((this.accomodationsForms.length - 1));
  }

  addMeals() {
    this.mealsForms.push(this.createMealsForm());
  }

  removeMeals() {
    this.mealsForms.removeAt((this.mealsForms.length - 1));
  }

  hasAccommodationLevels(accommodationForm) {
    const accommodationLevels = accommodationForm.get('accommodationType').value;
    return accommodationLevels === 'hotel' || accommodationLevels === 'cruise';
  }

  private updateEmissions(tonnes: number, form: any): void {
    const attendees = form['attendees'];
    const venue = form['venue'];
    const source = form['location'];
    const meals = form['meals'];
    const accommodations = form['accommodations'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          venue,
          attendees,
          source,
          meals,
          accommodations
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS
      );
  }

  private getEmissions() {
    const form = this.internalForm;

    this.emissionsRequestActivated = true;
    this.emissionsService.getEventEmissions(
      form['attendees'],
      form['meals'],
      form['accommodations'],
      form['venue']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;

    this.buildEventsForm();

    this.internalForm = undefined;
  }

  private createAttendeesForm(): FormGroup {
    return this.formBuilder.group({
      transportationType: [ this.eventTransporationTypes[0].apiKey, Validators.required ],
      transporationAttendees: [ 0, [ Validators.required, Validators.min(1) ] ],
      flightRange: [ this.eventFlightRanges[0].apiKey ],
      flightClass: [ this.eventFlightClasses[0].apiKey ]
    });
  }

  private createMealsForm(): FormGroup {
    return this.formBuilder.group({
      mealAmounts: [ 0, [ Validators.required, Validators.min(1) ] ],
      mealType: [this.mealTypes[0].apiKey, Validators.required]
    });
  }

  private createAccommodationsForm(): FormGroup {
    return this.formBuilder.group({
      accommodationNights: [ 0, [ Validators.required, Validators.min(1) ] ],
      accommodationType: [ this.accommodationTypes[0].apiKey, Validators.required],
      accommodationLevel: [ this.accommodationLevels[0].apiKey, Validators.required]
    });
  }

  private buildInternalForm() {
    const locationForm = this.eventForm.get('locationForm');
    // Are the basic values met?
    if ((!locationForm.valid)) {
        return null;
    }

    // Build the forms
    const attendessInternalForm = this.buildAttendeesForm();

    const form = {
      "attendees": attendessInternalForm,
      "meals": this.buildMealsForm(),
      "accommodations": this.buildAccommodationsForm(attendessInternalForm['totalGuests']),
      "venue": this.buildVenueForm()
    };

    return form;
  }

  private buildAttendeesForm() {
    const locationForm =
      this.eventForm.get('locationForm').value;

    const form = {
      totalGuests: 0,
      attendeesByCar: 0,
      attendeesByBus: 0,
      attendeesByRail: 0,
      attendeesByAirShort: 0,
      attendeesByAirBusinessShort: 0,
      attendeesByAirMedium: 0,
      attendeesByAirBusinessMedium: 0,
      attendeesByAirFirstMedium: 0,
      attendeesByAirLong: 0,
      attendeesByAirBusinessLong: 0,
      attendeesByAirFirstLong: 0,
      geography: locationForm['location']
    };

    this.eventForm.
      get('attendeesForms')['controls'].
      filter(element => element.valid).
      forEach(element => {
        const transportationType = element.get('transportationType').value;
        switch (transportationType) {
          case 'flight':
            const flightsKey = this.buildFlightsKey(element);
            form[flightsKey] +=
              element.get('transporationAttendees').value;
            break;
          default:
            const key = 'attendeesBy' +
              transportationType.charAt(0).toUpperCase() +
              transportationType.slice(1);
            form[key] +=
              element.get('transporationAttendees').value;
            break;
        }

        form.totalGuests += element.get('transporationAttendees').value;
      });

    return form;
  }

  private buildMealsForm() {
    const form = [];

    let totalMeals = 0;

    this.eventForm.
      get('mealsForms')['controls'].
      filter(element => element.valid).
      forEach(element => {
        const mealType = element.get('mealType').value;
        const mealAmounts = element.get('mealAmounts').value;

        form.push({
          level: mealType,
          amount: mealAmounts
        });

        totalMeals += mealAmounts;
      });

    form['totalMeals'] = totalMeals;

    return form;
  }

  private buildAccommodationsForm(totalGuests: number) {
    const form = [];

    const locationForm =
      this.eventForm.get('locationForm').value;

    let totalNights = 0;

    this.eventForm.
      get('accomodationsForms')['controls'].
      filter(element => element.valid).
      forEach(element => {
        const accommodationType = element.get('accommodationType').value;
        const accommodationLevel = element.get('accommodationLevel').value;
        const accommodationNights = element.get('accommodationNights').value;

        form.push({
          type: accommodationType,
          accommodationType: accommodationLevel,
          guests: totalGuests,
          nights: accommodationNights,
          source: locationForm['location']
        });

        totalNights += accommodationNights;
      });

    form['nights'] = totalNights;

    return form;
  }

  private buildVenueForm() {
    const form = this.eventForm.get('venueForm');

    const locationForm =
      this.eventForm.get('locationForm').value;

    const venueType = form.get('venueType').value;
    const venueArea = form.get('venueArea').value;
    const venueUnit = form.get('venueUnit').value;
    const eventDays = form.get('eventDays').value;
    const source = locationForm['location'];

      return {
        venueType,
        venueArea,
        venueUnit,
        eventDays,
        source
      };
  }

  private buildFlightsKey(element: FormGroup) {
    const flightClass = element.get('flightClass').value;
    const flightRange = element.get('flightRange').value;

    return 'attendeesByAir' +
      this.resolveClassName(flightClass, flightRange) +
      this.capitlizeName(flightRange);
  }

  private resolveClassName(flightClass: string, flightRange: string): string {
    return (flightClass === 'economy' || (flightClass === 'first' &&  flightRange === 'short'))
      ? '' : this.capitlizeName(flightClass);
  }

  private capitlizeName(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  private buildEventsForm() {
    this.attendeesForms = new FormArray([this.createAttendeesForm()]);
    this.mealsForms = new FormArray([this.createMealsForm()]);
    this.accomodationsForms = new FormArray([this.createAccommodationsForm()]);
    this.venueForm = this.formBuilder.group({
      venueType: [ this.venueTypes[0].apiKey ],
      venueArea: [ 0, Validators.required ],
      venueUnit: [ this.venueUnits[0].apiKey ],
      eventDays: [ 1, [ Validators.required, Validators.min(1) ] ]
    });
    this.locationForm = this.formBuilder.group({
      location: [ this.countries[0] ]
    });

    this.eventForm = this.formBuilder.group(
      {
        attendeesForms: this.attendeesForms,
        mealsForms: this.mealsForms,
        accomodationsForms: this.accomodationsForms,
        venueForm: this.venueForm,
        locationForm: this.locationForm
      }
    );
  }
}
