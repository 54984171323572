import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CartService } from '../../../shared/services/cart/cart.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

@Component({
  selector: 'app-climatecare-partially-addressed-mail',
  templateUrl: './partially-addressed-mail.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './partially-addressed-mail.component.css']
})
export class PartiallyAddressedMailComponent implements OnInit, FeatureComponent {
  data: any;

  partiallyAddressedForm: FormGroup;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.partiallyAddressedForm = this.formBuilder.group({
      small: [ 0 ],
      large: [ 0 ]
    });

    this.partiallyAddressedForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.partiallyAddressedForm.valid) {
        this.getEmissions(form);
      }
    });

  }

  ngOnInit(): void { }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    let params = {};

    if (form['small']) {
      params['smallAmount'] = form['small'];
      params['smallType'] = 'Partially Addressed Mail Postcards/Small Letters';
    }

    if (form['large']) {
      params['largeAmount'] = form['large'];
      params['largeType'] = 'Partially Addressed Mail Large Letters/Brochures/Magazines';
    }

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        params,
        EmissionsItemCatalog.OFFSET_CATEGORY_MAIL
      );
  }

  private getEmissions(form: any) {
    const mailEmissions = [
      { "type": "partial", "category": "small", "amount": form['small'] },
      { "type": "partial", "category": "large", "amount": form['large'] },
    ];

    this.emissionsRequestActivated = true;
    this.emissionsService.getMailEmissions(mailEmissions)
      .subscribe((tonnes: number) => {
        this.emissionsRequestActivated = false;
        this.updateEmissions(tonnes, form);
      });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.partiallyAddressedForm.patchValue({
      small: 0,
      large: 0
    });
  }
}

