
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AdditionalOptionsModule } from './additional-options/additional-options.module';
import { AdditionalOptionsComponent } from './additional-options/additional-options.component';
import { BusinessMailModule } from './business-mail/business-mail..module';
import { BusinessMailComponent } from './business-mail/business-mail.component';
import { CalculatorsApiAuthInterceptor } from '../../shared/interceptors/calculators-api-auth.interceptor';
import { CarbonAnalyticsFooterComponent } from './carbon-analytics-footer/carbon-analytics-footer.component';
import { CarbonAnalyticsFooterModule } from '../calculators/carbon-analytics-footer/carbon-analytics-footer.module';
import { CartComponent } from './cart/cart.component';
import { CartModule } from './cart/cart.module';
import { CartResolverService } from '../../shared/services/cart/cart-resolver.service';
import { CartService } from '../../shared/services/cart/cart.service';
import { ClimateCareAccommodationsComponent } from './accommodations/accommodations.component';
import { ClimateCareAccommodationsModule } from '../calculators/accommodations/accommodations.module';
import { ClimateCareConsumptionComponent } from './consumption/consumption.component';
import { ClimateCareConsumptionModule } from '../calculators/consumption/consumption.module';
import { ClimateCareEnergyComponent } from './energy/energy.component';
import { ClimateCareEnergyModule } from '../calculators/energy/energy.module';
import { ClimateCareFlightsComponent } from './flights/flights.component';
import { ClimateCareFlightsModule } from '../calculators/flights/flights.module';
import { ClimateCareFreightComponent } from './freight/freight.component';
import { ClimateCareFreightModule } from '../calculators/freight/freight.module';
import { ClimateCareRefrigerationComponent } from './refrigeration/refrigeration.component';
import { ClimateCareRefrigerationModule } from '../calculators/refrigeration/refrigeration.module';
import { ClimateCareStartComponent } from './start/start.component';
import { ClimateCareStartModule } from '../calculators/start/start.module';
import { ClimateCareTransportationComponent } from './transportation/transportation.component';
import { ClimateCareTransportationModule } from '../calculators/transportation/transportation.module';
import { ClimateCareWasteComponent } from './waste/waste.component';
import { ClimateCareWasteModule } from '../calculators/waste/waste.module';
import { ClimateCareEventComponent } from '../calculators/event/event.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationModule } from '../calculators/confirmation/confirmation.module';
import { ClimateCareEventModule } from '../calculators/event/event.module';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { CustomerDetailsModule } from './customer-details/customer-details.module';
import { DoorDropsModule } from './door-drops/door-drops.module';
import { DoorDropsComponent } from './door-drops/door-drops.component';
import { DirectMailModule } from './direct-mail/direct-mail.module';
import { DirectMailComponent } from './direct-mail/direct-mail.component';
import { EmissionsService } from '../../shared/services/emissions/emissions.service';
import { FeatureComponentRegistryService } from './feature-component-registry/feature-component-registry.service';
import { IcroaFooterComponent } from './icroa-footer/icroa-footer.component';
import { IcroaFooterModule } from '../calculators/icroa-footer/icroa-footer.module';
import { IntroComponent } from './intro/intro.component';
import { IntroModule } from './intro/intro.module';
import { OffsetProjectsModule } from '../calculators/offset-projects/offset-projects.module';
import { OffsetProjectsComponent } from './offset-projects/offset-projects.component';
import { PartiallyAddressedMailModule } from '../calculators/partially-addressed-mail/partially-addressed-mail.module';
import { PartiallyAddressedMailComponent } from '../calculators/partially-addressed-mail/partially-addressed-mail.component';
import { PayOffsetsComponent } from '../calculators/pay-offsets/pay-offsets.component';
import { PayOffsetsModule } from '../calculators/pay-offsets/pay-offsets.module';
import { PostPurchaseNavigationModule } from './post-purchase-navigation/post-purchase-navigation.module';
import { PostPurchaseNavigationComponent } from './post-purchase-navigation/post-purchase-navigation.component';
import { PurchaseEmissionsComponent } from './purchase-emissions/purchase-emissions.component';
import { PurchaseEmissionsModule } from '../calculators/purchase-emissions/purchase-emissions.module';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CalculatorsApiAuthInterceptor, multi: true }
];

@NgModule({
    imports: [
      CommonModule,
      AdditionalOptionsModule,
      BusinessMailModule,
      CartModule,
      ClimateCareTransportationModule,
      ClimateCareStartModule,
      ClimateCareAccommodationsModule,
      ClimateCareConsumptionModule,
      ClimateCareEnergyModule,
      ClimateCareFlightsModule,
      ClimateCareFreightModule,
      ClimateCareRefrigerationModule,
      ClimateCareEventModule,
      ClimateCareTransportationModule,
      ClimateCareWasteModule,
      PurchaseEmissionsModule,
      CarbonAnalyticsFooterModule,
      ConfirmationModule,
      CustomerDetailsModule,
      DoorDropsModule,
      DirectMailModule,
      IcroaFooterModule,
      OffsetProjectsModule,
      PayOffsetsModule,
      PartiallyAddressedMailModule,
      PostPurchaseNavigationModule,
      IntroModule
    ],
    providers: [
      FeatureComponentRegistryService,
      httpInterceptorProviders,
      CartService,
      CartResolverService,
      EmissionsService
    ],
    entryComponents: [
      AdditionalOptionsComponent,
      BusinessMailComponent,
      CarbonAnalyticsFooterComponent,
      CartComponent,
      ConfirmationComponent,
      ClimateCareStartComponent,
      ClimateCareAccommodationsComponent,
      ClimateCareConsumptionComponent,
      ClimateCareEnergyComponent,
      ClimateCareFlightsComponent,
      ClimateCareFreightComponent,
      ClimateCareRefrigerationComponent,
      ClimateCareEventComponent,
      ClimateCareTransportationComponent,
      ClimateCareWasteComponent,
      CustomerDetailsComponent,
      DoorDropsComponent,
      DirectMailComponent,
      IcroaFooterComponent,
      IntroComponent,
      OffsetProjectsComponent,
      PartiallyAddressedMailComponent,
      PayOffsetsComponent,
      PostPurchaseNavigationComponent,
      PurchaseEmissionsComponent
    ],
    declarations: []
})
export class ClimateCareCalculatorModule {}
