import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"

import { TranslateModule } from '@ngx-translate/core';

import { ClimateCareStartComponent } from './start.component';
import { OffsetInputModule } from '../../../shared/components/offset-input/offset-input.module';
import { PurchaseEmissionsModule } from '../purchase-emissions/purchase-emissions.module';

@NgModule({
  declarations: [
    ClimateCareStartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OffsetInputModule,
    PurchaseEmissionsModule,
    RxReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    ClimateCareStartComponent
  ],
})
export class ClimateCareStartModule { }
