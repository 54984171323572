import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AirportService } from '../../../shared/services/airports/airport-service';
import { GenericTypeaheadModule } from '../../../shared/components/generic-typeahead/generic-typeahead.module';
import { ClimateCareFlightsComponent } from './flights.component';
import { PurchaseEmissionsModule } from '../purchase-emissions/purchase-emissions.module';

@NgModule({
  declarations: [
    ClimateCareFlightsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbTypeaheadModule,
    PurchaseEmissionsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    ClimateCareFlightsComponent
  ],
  providers: [
    AirportService
  ]
})
export class ClimateCareFlightsModule {
}
