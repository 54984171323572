import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { EmissionsItem } from './emissions-item.interface';
import { EmissionsItemCatalog } from './emissions-item-catalog';
import { EmissionsOption, EmissionsOptionsCatalog } from './emissions-options-catalog';
import { EmissionsRequest } from './emissions-request.interface';
import { EMISSIONS_SERVICE_CONSTANTS } from './emissions.constants';
import { MailEmissionsRequestParams } from './mail-emissions-request.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EmissionsService {

  private currentEmissions: BehaviorSubject<number>;

  private emissionsRequestActivated: BehaviorSubject<boolean>;

  private emissionsItemCatalog: EmissionsItemCatalog;

  private emissionsOptionsCatalog: EmissionsOptionsCatalog;

  constructor(
    private appConfiguration: AppConfiguration,
    private httpClient: HttpClient,
    private translate: TranslateService) {

    this.translate.setDefaultLang('en');
    this.translate.use(this.appConfiguration.clientConfiguration.clientSettings.defaultLanguage);
    this.emissionsItemCatalog = new EmissionsItemCatalog(translate);
    this.emissionsOptionsCatalog = new EmissionsOptionsCatalog(appConfiguration);

    this.currentEmissions = new BehaviorSubject<number>(0);

    this.emissionsRequestActivated = new BehaviorSubject<boolean>(false);

  }

  getEmissionItem(params: any, catalogName: string): EmissionsItem {
    const item = this.emissionsItemCatalog.getEmissionsItem(catalogName);

    switch (catalogName) {
      case EmissionsItemCatalog.OFFSET_CATEGORY_GENERAL:
        item.description[1] =
          item.description[1].replace('{amount}', params.tonnes.toFixed(2));
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION:
        item.description[1] =
          item.description[1]
            .replace('{passengers}', params.passengers.toString())
            .replace('{flightType}', params.flightType)
            .replace('{flightClass}', params.flightClass)
            .replace('{departureCity}', params.departureCity)
            .replace('{connectionCity}', params.connectionCity ? ', via ' + params.connectionCity : '')
            .replace('{destinationCity}', params.destinationCity);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_TRANSPORTAION_GENERIC:
        item.description[1] =
          item.description[1]
            .replace('{fuelType}', params.fuelType)
            .replace('{type}', params.type)
            .replace('{distance}', params.distance)
            .replace('{distanceUnit}', params.distanceUnit)
            .replace('{source}', params.source ? ', purchased in ' + params.source : '')
            .replace('{journeys}', params.journeys);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_ENERGY:
        item.description[1] =
          item.description[1]
            .replace('{energyType}', params.energyType)
            .replace('{amount}', params.amount)
            .replace('{unit}', params.unit)
            .replace('{country}', params.country ? ', ' + params.country : '')
            .replace('{renewable}', params.isRenewable ? EmissionsItemCatalog.OFFSET_RENEWABLE_MESSAGE : '');
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_CONSUMPTION:
        item.description[1] =
          item.description[1]
            .replace('{meal}', params.mealType)
            .replace('{amount}', params.amount);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_ACCOMMODATION:
        item.description[1] =
          item.description[1]
            .replace('{type}', params.type)
            .replace('{accommodationType}', params.accommodationType)
            .replace('{guests}', params.guests)
            .replace('{nights}', params.nights)
            .replace('{guestsCaption}', params.guests);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_FREIGHT:
        item.description[1] =
          item.description[1]
            .replace('{distance}', params.distance)
            .replace('{unit}', params.unit)
            .replace('{freightClass}', params.freightClass)
            .replace('{weight}', params.weight)
            .replace('{weightUnit}', params.weightUnit);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_EVENTS:
        item.description[1] =
          item.description[1]
            .replace('{venueType}', params.venue.venueType ? params.venue.venueType : '')
            .replace('{venueArea}', params.venue.venueArea ? params.venue.venueArea : '')
            .replace('{venueUnit}', params.venue.venueUnit ? params.venue.venueUnit : '')
            .replace('{attendeesByCar}', params.attendees.attendeesByCar ? ', ' + params.attendees.attendeesByCar + ' attendees by car' : '')
            .replace('{attendeesByBus}', params.attendees.attendeesByBus ? ', ' + params.attendees.attendeesByBus + ' attendees by bus' : '')
            .replace('{attendeesByRail}', params.attendees.attendeesByRail ? ', ' + params.attendees.attendeesByRail + ' attendees by rail' : '')
            .replace('{attendeesByAirShort}', params.attendees.attendeesByAirShort ? ', ' + params.attendees.attendeesByAirShort + ' attendees by short flight economy class' : '')
            .replace('{attendeesByAirBusinessShort}', params.attendees.attendeesByBusinessShort ? ', ' + params.attendees.attendeesByBusinessShort + ' attendees by short range flight business class' : '')
            .replace('{attendeesByAirMedium}', params.attendees.attendeesByMedium ? ', ' + params.attendees.attendeesByMedium + ' attendees by medium range flight economy class' : '')
            .replace('{attendeesByAirBusinessMedium}', params.attendees.attendeesByBusinessMedium ? ', ' + params.attendees.attendeesByBusinessMedium + ' attendees by medium range flight business class' : '')
            .replace('{attendeesByAirFirstMedium}', params.attendees.attendeesByFirstMedium ? ', ' + params.attendees.attendeesByFirstMedium + ' attendees by medium range flight first class' : '')
            .replace('{attendeesByAirLong}', params.attendees.attendeesByAirLong ? ', ' + params.attendees.attendeesByAirLong + ' attendees by long range flight economy class' : '')
            .replace('{attendeesByAirBusinessLong}', params.attendees.attendeesByAirBusinessLong ? ', ' + params.attendees.attendeesByAirBusinessLong + ' attendees by long range flight business class' : '')
            .replace('{attendeesByAirFirstLong}', params.attendees.attendeesByAirFirstLong ? ', ' + params.attendees.attendeesByAirFirstLong + ' attendees by long range flight first class' : '')
            .replace('{meals}', this.parseMealsString(params))
            .replace('{accommodations}', this.parseAccommodationsString(params))
            .replace('{source}', params.venue.source ? params.venue.source : '');
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_WASTE:
        item.description[1] =
          item.description[1]
            .replace('{type}', params.type)
            .replace('{endOfLife}', params.endOfLife)
            .replace('{unit}', params.unit)
            .replace('{weight}', params.weight);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_MAIL:
        item.description[1] =
          this.buildMailItemDescription(params);
        break;
      case EmissionsItemCatalog.OFFSET_CATEGORY_DROPS:
        item.description[1] =
          item.description[1].replace('{drops}', params.drops + ' packages');
        break;
    }

    return item;
  }

  private buildMailItemDescription(params: any): string
  {
      let description = '';
      let small = '';
      let large = '';

      if (params.smallAmount && params.smallType) {
        small = params.smallAmount + ' ' + params.smallType;
      }

      if (params.largeAmount && params.largeType) {
        large = params.largeAmount + ' ' + params.largeType;
      }

      if (small) {
        description += small;
      }

      if (small && large) {
        description += ', ';
      }

      if (large) {
        description += large;
      }

      return description;
  }

  getFieldOptions(optionsType: string): Array<EmissionsOption> {
    return this.emissionsOptionsCatalog.getOptions(optionsType);
  }

  getCountries(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(
      this.appConfiguration.clientConfiguration.endpoints.getCountries,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((countries: Array<string>) => {
        const countryFilter = this.getCountryFilter();

        return countries.filter(item => countryFilter.indexOf(item) === -1);
      }),
      catchError(this.handleError)
    );
  }

  updateCurrentEmissions(emissions: number) {
    this.currentEmissions.next(emissions);
  }

  getCurrentEmissions(): BehaviorSubject<number> {
    return this.currentEmissions;
  }

  getEmissionsRequestActivated(): BehaviorSubject<boolean> {
    return this.emissionsRequestActivated;
  }

  setEmissionsRequestActivated(active: boolean) {
    return this.emissionsRequestActivated.next(active);
  }

  getGeneralEmissions(cost: number, currency: string): Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_EMISSIONS_PER_UNIT_COST,
      summary: [
        {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_EMISSIONS_PER_UNIT_COST,
          cost,
          currency
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  }

  getFlightEmissions(
    departureAirport: string,
    destinationAirport: string,
    flightType: string,
    flightClass: string,
    passengers: number,
    connectionAirport?: string,
    weightTonnes?: number): Observable<number | Array<string>> {
    
    const tranSportation : any = {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_FLIGHT,
          departureAirport,
          destinationAirport,
          flightType,
          class: flightClass,
          passengers,
          weightTonnes: weightTonnes ? weightTonnes : 0
    }

    const allowedClients = ["chinaair", "evaair"];
    if(allowedClients.includes(this.appConfiguration.clientConfiguration.clientSettings.client)){
      tranSportation.client = this.appConfiguration.clientConfiguration.clientSettings.client
    }

    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_FLIGHT,
      transportation: [tranSportation]
    };

    if (connectionAirport) {
      emissionsRequest.transportation[0]['connectionAirport'] = connectionAirport;
    }

    return this.getEmissions(emissionsRequest);
  }

  getTransportationEmissions(
    type: string,
    distance: number,
    unit: string,
    passengers: number,
    fuelType: string,
    source: string):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_TRANSPORATION,
      transportation: [
        {
          type,
          distance: (distance * passengers),
          unit,
          fuelType,
          source
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  };

  getEnergyEmissions(type: string, consumption: number, unit: string, source: string):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_ELECTRICITY,
      energy: [
        {
          type,
          consumption,
          unit,
          source
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  };

  getConsumptionEmissions(level: string, amount: number):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_DIET,
      consumption: [
        {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_DIET,
          level,
          amount
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  }

  getMailEmissions(mailEmissions: Array<MailEmissionsRequestParams>):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_MAIL,
      mail: mailEmissions
    };

    return this.getEmissions(emissionsRequest);
  }

  getAccommodationsEmissions(
    type: string,
    accommodationType: string,
    guests: number,
    nights: number,
    source: string):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_HOTEL,
      travel: [
        {
          type,
          accommodationType,
          guests,
          nights,
          source
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  }

  getFreightEmissions(
    distance: number,
    unit: string,
    medium: string,
    freightClass: string,
    weight: number,
    weightUnit: string):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_FREIGHT,
      transportation: [
        {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_FREIGHT,
          distance,
          unit,
          medium,
          class: freightClass,
          weight,
          weightUnit
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  }

  getWasteEmissions(
    type: string,
    endOfLife: string,
    unit: string,
    weight: number):
    Observable<number | Array<string>> {
    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_WASTE,
      waste: [
        {
          type,
          endOfLife,
          unit,
          weight
        }
      ]
    };

    return this.getEmissions(emissionsRequest);
  }

  getEventEmissions(attendees: any, meals: Array<any>, accommodations: Array<any>, venue: any):
    Observable<number | Array<string>> {

    const emissionsRequest: EmissionsRequest = {
      eventType: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_EVENT,
      events: [
        {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_EVENT,
          attendeesByCar: attendees['attendeesByCar'],
          attendeesByBus: attendees['attendeesByBus'],
          attendeesByRail: attendees['attendeesByRail'],
          attendeesByAirShort: attendees['attendeesByAirShort'],
          attendeesByAirBusinessShort: attendees['attendeesByAirBusinessShort'],
          attendeesByAirMedium: attendees['attendeesByAirMedium'],
          attendeesByAirBusinessMedium: attendees['attendeesByAirBusinessMedium'],
          attendeesByAirFirstMedium: attendees['attendeesByAirFirstMedium'],
          attendeesByAirLong: attendees['attendeesByAirLong'],
          attendeesByAirBusinessLong: attendees['attendeesByAirBusinessLong'],
          attendeesByAirFirstLong: attendees['attendeesByAirFirstLong'],
          geography: attendees['geography']
        }
      ]
    };

    if (meals.length) {
      emissionsRequest.consumption = [];

      meals.forEach(meal => {
        emissionsRequest.consumption.push(
          {
            type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_DIET,
            level: meal.level,
            amount: meal.amount
          }
        );
      });
    }

    if (accommodations.length) {
      emissionsRequest.travel = [];

      accommodations.forEach(accommodation => {
        emissionsRequest.travel.push(
          {
            type: accommodation.type,
            accommodationType: accommodation.accommodationType,
            guests: 1,
            nights: accommodation.nights,
            source: accommodation.source
          }
        );
      });
    }

    if (venue) {
      const buildingArea = ((venue.venueArea / 365) * venue.eventDays).toFixed(2);

      emissionsRequest.energy = [
        {
          type: EMISSIONS_SERVICE_CONSTANTS.emissionTypes.EMISSIONS_TYPE_ELECTRICITY,
          buildingUsage: venue.venueType,
          buildingArea: buildingArea,
          unit: venue.venueUnit,
          source: venue.source
        }
      ];
    }

    return this.getEmissions(emissionsRequest);
  }

  private getEmissions(request: EmissionsRequest): Observable<number | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.clientConfiguration.endpoints.getEmissions,
      request,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((data: any) => {
        return parseFloat(data.co2e);
      }),
      map((emissions: number) => {
        this.currentEmissions.next(emissions);
        return emissions;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    if (error.error) {
      return of((error.error.errors));
    }
  }

  private getCountryFilter(): Array<string> {
    return [
      "Chinese Taipei",
      "Africa (average)",
      "EU (average)",
      "Latin America (average)",
      "Middle East (average)",
      "NonOECD Europe Eurasia",
      "World (average)",
      "World (average)",
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
      "Asia (average)"
    ];
  }

  private parseMealsString(params): string {
    let mealsStr = '';

    if (params.meals && params.meals.length) {
      params.meals.forEach(meal => {
        mealsStr += ', ' + meal.amount + ' meals of type ' + meal.level;
      });
    }

    return mealsStr;
  }

  private parseAccommodationsString(params): string {
    let accommodationsStr = '';

    if (params.accommodations && params.accommodations.length) {
      params.accommodations.forEach(accommodation => {
        accommodationsStr += ', ' + accommodation.nights + ' nights in ' + accommodation.type;
      });
    }

    return accommodationsStr;
  }
}
