<div class="calculator-form">
  <div class="cart-pricing-table table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">
            <div>{{ 'cart.portfolio-caption' | translate }}</div>
          </th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.uc-tonnes-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.price-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.vat-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.total-cost-caption' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'cart.message-caption' | translate }}</td>
          <td>{{ cart.totalTonnes | number: "1.2-2" }}</td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.costPerTonne | number: "1.2-2" }}
          </td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.vatAmount | number: "1.2-2" }}
          </td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.totalCost | number: "1.2-2" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="customer-details-form">
    <app-flex-stripe-card
      (cardCompleted)="cardCompleted($event)"
    ></app-flex-stripe-card>
  </div>
</div>
<div>
  <app-post-purchase-navigation
    [target]="data.id"
    [nextEnabled]="paymentState && paymentState.cardComplete"
    [secondCaption]="payCaption"
    [hasNext]="true"
    [activated]="paying"
    (next)="pay()"
    (back)="back()">
  </app-post-purchase-navigation>
</div>
