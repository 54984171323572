import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import {
  ContentBlock,
  Project,
  ProjectDetails
} from './project.interface';
import { PartnerConfig } from './partner-config.interface';
import { VoucherCode } from './voucher-code.interface';

const PROP_DESCRIPTION = 'description';
const PROP_BASE_CURRENCY = 'baseCurrency';

@Injectable()
export class CalculatorApi {

  private endpoint: string;

  private dataPropertiesMap = {
    id: 'id',
    name: 'title',
    title: 'title',
    base64_image_data: 'splash',
    cost_currency_code_iso_4217: 'baseCurrency',
    cost_per_tonne_co2e: 'costPerTonne',
    contents: 'contents',
    paragraph: 'paragraph',
    link: 'link',
    description: 'description'
  };

  private projects: BehaviorSubject<Array<Project>>;

  private selectedProject: BehaviorSubject<Project>;

  constructor(
    private appConfiguration: AppConfiguration,
    private httpClient: HttpClient) {

    this.endpoint =
      this.appConfiguration.clientConfiguration.clientSettings.apis.domain + '/' +
      this.appConfiguration.clientConfiguration.clientSettings.apis.version;

    this.selectedProject = new BehaviorSubject<Project>(null);
  }

  getVoucherCode(code: string): Observable<VoucherCode> {
    const queryParams: URLSearchParams = new URLSearchParams();

    queryParams.set('code', code);

    return this.get('voucher', false, queryParams);
  }

  getPartnerConfig(partnerConfig: PartnerConfig): Observable<PartnerConfig | HttpErrorResponse> {
    const queryParams: URLSearchParams = new URLSearchParams();

    queryParams.set('partnerKey', partnerConfig.partnerKey);
    queryParams.set('domain', partnerConfig.domain);

    return this.get('license', false, queryParams).
      pipe(
        map((data: any) => {
          if (data.status && data.status !== 200) {
            return data;
          } else {
            partnerConfig.data = data;

            this.appConfiguration.clientConfiguration.clientSettings.partnerConfig =
              JSON.stringify(partnerConfig);

            return partnerConfig;
          }
        }),
        catchError(this.handleError)
      );
  }

  getSelectedProject(): Observable<Project> {
    return this.selectedProject;
  }

  setSelectedProject(project: Project) {
    this.selectedProject.next(project);
  }

  clearSelectedProject() {
    this.selectedProject.next(null);
  }

  getProjects(cacheData?: boolean): Observable<Array<Project> | HttpErrorResponse> {
    if (this.projects) {
      return this.projects;
    }

    return this.get('climate-projects', true).
      pipe(
        map((data: any) => {
          return this.mapProjects(data);
        }),
        map((projects: Array<Project>) => {
          if (cacheData) {
            this.projects = new BehaviorSubject<Array<Project>>([]);
            this.selectedProject.next(projects[0]);
            this.projects.next(projects);
          }
          return projects;
        }),
        catchError(this.handleError)
      );
  }

  private get(resource: string, useCreds: boolean, queryParams?: URLSearchParams): Observable<any> {
    return this.httpClient.get<any>(
      this.endpoint + '/' + resource + (queryParams ? '?' + queryParams.toString() : ''),
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private mapProjects(data: any): Array<Project> {
    const lang =
      this.appConfiguration.clientConfiguration.clientSettings.defaultLanguage;

    const projects: Array<Project> = [];

    data.forEach((projectData: any) => {
      const project: Project = {};

      for (const prop in projectData) {
        if (projectData.hasOwnProperty(prop)) {
          const mapped = this.dataPropertiesMap[prop];

          if (mapped &&
            mapped !== PROP_DESCRIPTION &&
            mapped !== PROP_BASE_CURRENCY) {
            project[mapped] = projectData[prop];
          } else if (mapped && mapped === PROP_DESCRIPTION) {
            const details: any = JSON.parse(projectData[mapped]);
            project.projectDetails = this.mapProjectDetails(details[lang]);
          } else if (mapped && mapped === PROP_BASE_CURRENCY) {
            const currency = this.mapCurrency(projectData[prop]);
            project.baseCurrency = currency;
            project.quotedCurrency = currency;
            project.quotedCostPerTonne = project.costPerTonne;
          }
        }
      }

      project.quotedCostPerTonne = project.costPerTonne;

      projects.push(project);
    });

    return projects;
  }

  private mapProjectDetails(details: any): ProjectDetails {
    const projectDetails: ProjectDetails = {};

    try {
      if (details && details.title && typeof (details.title) === 'string') {
        projectDetails.title = details.title;
      }

      if (details && details.contents && Array.isArray(details.contents)) {
        if (!projectDetails.contents) {
          projectDetails.contents = [];
        }

        details.contents.forEach((contentBlock: ContentBlock) => {
          projectDetails.contents.push(contentBlock);
        });
      }
    } catch (e) { }

    return projectDetails;
  }

  private mapCurrency(iso2: string): any {
    let currency;

    const payableCurrencies =
      this.appConfiguration.clientConfiguration.payableCurrencies;

    for (const payableCurrency of payableCurrencies) {
      if (payableCurrency.iso2 === iso2) {
        currency = payableCurrency;
        break;
      }
    }

    return currency;
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    return of(error);
  }
}
