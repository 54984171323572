<div class="calculator-form row">
  <div class="col-md-7">
    <form [formGroup]="consumptionForm">
      <div class="calculator-form-header form-group">
        <div class="calculator-form-title">FOOD</div>
        <p>Calculate and offset emissions from meals</p>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Type of meals</label>
        <select class="form-control" formControlName="level">
          <option
            *ngFor="let mealType of mealTypes"
            value="{{ mealType.apiKey }}"
          >
            {{ mealType.label }}
          </option>
        </select>
      </div>
      <div class="form-group inline">
        <label class="col-form-label">Number of meals</label>
        <input
          type="number"
          class="form-control distance"
          formControlName="amount"
        />
      </div>
    </form>
  </div>
  <div class="col-md-5">
    <app-purchase-emissions
      [emissionsRequestActivated]="emissionsRequestActivated"
      [emissionsRequestValid]="consumptionForm.valid"
      [emissionsItem]="emissionsItem"
      [totalEmissions]="displayedTonnes"
      (purchaseAction)="purchaseAction($event)"
    >
    </app-purchase-emissions>
  </div>
</div>
