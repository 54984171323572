<div class="calculator-form">
  <div class="cart-pricing-table table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">
            <div>{{ 'cart.portfolio-caption' | translate }}</div>
          </th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.uc-tonnes-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.price-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.vat-caption' | translate }}</th>
          <th [ngStyle]="{'background-color': customCartHeaderColor}" scope="col">{{ 'cart.total-cost-caption' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'cart.message-caption' | translate }}</td>
          <td>{{ cart.totalTonnes | number: "1.2-2" }}</td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.costPerTonne | number: "1.2-2" }}
          </td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.vatAmount | number: "1.2-2" }}
          </td>
          <td>
            {{ cart.currency.iso2 | currencySymbol
            }}{{ cart.totalCost | number: "1.2-2" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="customer-details-form">
    <form [formGroup]="detailsForm">
      <div class="calculator-form-title-small mb-2">{{ 'customer-information.header' | translate }}</div>
      <div class="form-group row">
        <label for="customerName" class="col-sm-2 col-form-label">{{ 'customer-information.name-caption' | translate }}</label>
        <div class="col-sm-10">
          <input
            type="text"
            name="customerName"
            class="form-control"
            placeholder="{{ 'customer-information.customer-name-placeholder' | translate }}"
            [formControl]="detailsForm.controls['customerName']"
          />
      </div>
      </div>
      <div class="form-group row">
        <label for="organizationName" class="col-sm-2 col-form-label">{{ 'customer-information.organization-caption' | translate }}</label>
        <div class="col-sm-10">
          <input
            type="text"
            name="organizationName"
            class="form-control"
            placeholder="{{ 'customer-information.organization-name-placeholder' | translate }}"
            [formControl]="detailsForm.controls['organizationName']"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="customerEmail" id="calculator-details-email" class="col-sm-2 col-form-label">{{ 'customer-information.email-caption' | translate }}</label>
        <div class="col-sm-10">
          <input
            type="email"
            name="customerEmail"
            class="form-control"
            placeholder="{{ 'customer-information.customer-email-placeholder' | translate }}"
            [formControl]="detailsForm.controls['customerEmail']"
          />
        </div>
      </div>
      <div class="form-group inline choose-payment">
        <div class="calculator-form-title-small">{{ 'customer-information.organization-name-confirmation' | translate }}</div>
      </div>
      <!-- <div *ngIf="detailsForm.valid" class="form-group inline choose-payment">
        <div class="calculator-form-title-small">{{ 'customer-information.payment-method-caption' | translate }}</div>
      </div> -->
    </form>
  </div>
</div>
<div>
  <app-post-purchase-navigation
    [target]="customerDetailsTarget"
    [nextEnabled]="detailsForm.valid"
    [middleCaption]="middleCaption"
    [secondCaption]="rightCaption"
    [hasNext]="true"
    [hasMiddle]="middleCaption"
    [middleActivated]="payingPaypal"
    [activated]="paying"
    (back)="back()"
    (middle)="middle()"
    (next)="next()">
  </app-post-purchase-navigation>
</div>
