import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IntroComponent } from './intro.component';
import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';

@NgModule({
  declarations: [
    IntroComponent
  ],
  imports: [
    CommonModule,
    FeatureScrollerModule,
    TranslateModule
  ],
  exports: [
    IntroComponent
  ]
})
export class IntroModule {
}
