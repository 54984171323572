import { BehaviorSubject } from 'rxjs';

import { environment } from '../../bootstrap/climatecare/environment';

export class ClimateCareAppConfiguration {

  selectedCurrency: BehaviorSubject<any>;

  countries: BehaviorSubject<Array<any>>;

  endpoints;

  license;

  defaultLogoImg = {
    name: 'climate-care-logo-2-resized.png',
    height: '110px',
    width: '180px'
  };

  constructor(
    public clientSettings: any,
    public payableCurrencies = [
      { symbol: '£', iso2: 'GBP' },
      { symbol: '€', iso2: 'EUR' },
      { symbol: '$', iso2: 'USD' },
    ],
    public navAsSelect = true,
    public stripePublishableKey?: string,
    public tabs?: Array<string>) {

    this.clientSettings = environment;

    this.license = this.clientSettings.license;
    this.stripePublishableKey = this.clientSettings.stripePublishableKey;
    this.endpoints = {
      getLicense: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/license',
      getEmissions: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/emissions/multi',
      getAirports: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/airports',
      getCountries: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/countries',
      getProjects: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/climate-projects',
      getFxRate: clientSettings.apis.fx + '/rate',
      initPayment: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/payment',
      completePayment: clientSettings.apis.domain + '/' + clientSettings.apis.version + '/payment'
    };

    this.selectedCurrency = new BehaviorSubject<any>(this.payableCurrencies[0]);
    this.countries = new BehaviorSubject<Array<any>>([]);
  }

  getSelectedCurrency(): BehaviorSubject<any> {
    return this.selectedCurrency;
  }

  updateSelectedCurrency(currencyIndex: number): void {
    this.selectedCurrency.next(this.payableCurrencies[currencyIndex]);
  }

  getCountries(): BehaviorSubject<Array<any>> {
    return this.countries;
  }
}
