import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-climatecare-waste',
  templateUrl: './waste.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './waste.component.css' ]
})
export class ClimateCareWasteComponent implements OnInit, FeatureComponent {

  data: any;

  wasteForm: FormGroup;

  wasteTypes: Array<EmissionsOption>;

  wasteUnitTypes: Array<EmissionsOption>;

  wasteEolTypes: Array<EmissionsOption>;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private cartService: CartService;

  private emissionsService: EmissionsService;

  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.wasteTypes = this.emissionsService.getFieldOptions('wasteTypes');
    this.wasteUnitTypes = this.emissionsService.getFieldOptions('wasteUnitTypes');
    this.wasteEolTypes = this.emissionsService.getFieldOptions('wasteEolTypes');

    this.wasteForm = this.formBuilder.group({
      type: [ this.wasteTypes[0].apiKey, [ Validators.required, Validators.min(1) ] ],
      endOfLife: [ this.wasteEolTypes[0].apiKey, Validators.required ],
      unit: [ this.wasteUnitTypes[0].apiKey, Validators.required ],
      weight: [ 0, [ Validators.required, Validators.min(1) ] ]
    });

    this.wasteForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.wasteForm.valid) {
        this.getEmissions(form);
      }
    });
  }

  ngOnInit(): void {}

  changeWasteType(index: number) {
    this.wasteEolTypes = this.wasteTypes[index].units[0];

    this.wasteForm.patchValue({
      endOfLife: this.wasteEolTypes[0].apiKey
    });
  }

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const type = form['type'];
    const endOfLife = form['endOfLife'];
    const unit = form['unit'];
    const weight = form['weight'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          type,
          endOfLife,
          unit,
          weight
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_WASTE
      );
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getWasteEmissions(
      form['type'],
      form['endOfLife'],
      form['unit'],
      form['weight']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.wasteForm.patchValue({
      type: this.wasteTypes[0].apiKey,
      endOfLife: this.wasteEolTypes[0].apiKey,
      unit: this.wasteUnitTypes[0].apiKey,
      weight: 0
    });
  }
}
