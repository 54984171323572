import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmissionsItemCatalog } from '../../../shared/services/emissions/emissions-item-catalog';

import { CartService } from '../../../shared/services/cart/cart.service';
import { EmissionsItem } from '../../../shared/services/emissions/emissions-item.interface';
import { EmissionsOption } from '../../../shared/services/emissions/emissions-options-catalog';
import { EmissionsService } from '../../../shared/services/emissions/emissions.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';

@Component({
  selector: 'app-climatecare-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: [ '../../../../assets/css/climatecare/app.css', './consumption.component.css']
})
export class ClimateCareConsumptionComponent implements OnInit, FeatureComponent {

  data: any;

  consumptionForm: FormGroup;

  mealTypes: Array<EmissionsOption>;

  tonnes = 0;

  displayedTonnes = 0;

  emissionsRequestActivated = false;

  emissionsRequestValid = false;

  emissionsItem: EmissionsItem;

  errors: Array<string> = [];

  private cartService: CartService;
  private emissionsService: EmissionsService;
  private formBuilder: FormBuilder;

  constructor(private injector: Injector) {
    this.cartService = this.injector.get(CartService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.emissionsService = this.injector.get(EmissionsService);

    this.mealTypes = this.emissionsService.getFieldOptions('mealTypes');

    this.consumptionForm = this.formBuilder.group({
      level: [ this.mealTypes[0].apiKey, Validators.required ],
      amount: [ 0, [ Validators.required ] ]
    });

    this.consumptionForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((form) => {
      if (this.consumptionForm.valid) {
        this.getEmissions(form);
      }
    });
  }

  ngOnInit(): void {}

  purchaseAction(emissionsItem: EmissionsItem) {
    this.cartService.addItem({
      emissionsItem,
      emissions: this.displayedTonnes
    });
    this.reset();
  }

  private updateEmissions(tonnes: number, form: any): void {
    const mealType = form['level'];
    const amount = form['amount'];

    this.displayedTonnes = tonnes;
    this.emissionsItem =
      this.emissionsService.getEmissionItem(
        {
          mealType,
          amount
        },
        EmissionsItemCatalog.OFFSET_CATEGORY_CONSUMPTION
      );
  }

  private getEmissions(form: any) {
    this.emissionsRequestActivated = true;
    this.emissionsService.getConsumptionEmissions(
      form['level'],
      form['amount']
    ).subscribe((tonnes: number) => {
      this.emissionsRequestActivated = false;
      this.updateEmissions(tonnes, form);
    });
  }

  private reset(): void {
    this.displayedTonnes = 0;
    this.tonnes = 0;
    this.emissionsRequestValid = false;
    this.consumptionForm.patchValue({
      type: this.mealTypes[0].apiKey,
      amount: 0
    });
  }
}
